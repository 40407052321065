@charset "UTF-8";
/*----------------------------------------------------------------------

/*----------------------------------------------------------------------
	CSS INDEX
	----------------------

    ## Default Style
    ## Common Classes
    ## Repeat Style
    ## Padding Margin Spacing
    ## Custom Animation
    ## Header style
    ## Page Banner
    ## Main Slider
    ## Hero Section
    ## About Section
    ## Contact Section
    ## Features Area
    ## Counter Section
    ## Rooms Section
    ## Hotel Section
    ## Blog Area
    ## Video Section
    ## Activity Area
    ## Services Area
    ## Sidebar Widgets
    ## Testimonials Area
    ## Search Filter
    ## Instagram Area
    ## Gallery Area
    ## Pricing Section
    ## Skillbar Area
    ## History Area
    ## FAQs Area
    ## Shop Area
    ## Team Area
    ## Main Footer */
/* -------------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,600;0,700;1,400&amp;family=Roboto:wght@400;500&amp;display=swap");
/*******************************************************/
/******************* ## Default Style *****************/
/*******************************************************/

@media only screen and (max-width: 600px) {
  .slider-item {
    padding-bottom: 50px !important;
  }

  .menu-btns {
    display: block !important;
  }

  .login-mobile {
    margin-left: 10px !important;
    color: #fff !important;
  }
}

@layer library, reset, base, demo;
@import "https://unpkg.com/open-props@2.0.0-beta.5" layer(library);

@layer reset {

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  :where(:not(dialog)) {
    margin: 0;
  }
}

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* 
body {
  color: var(--base-color);
  background: white;
  font-weight: 400;
  line-height: 32px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  --base-color: #696969;
  --heading-color: #0A0807;
  --primary-color: #9f7a39;
  --lighter-color: #F4F2F1;
  --black-color: #0C0B0B;
  --border-color: #D9DFE7; } */

a {
  /* color: var(--base-color); */
  cursor: pointer;
  outline: none;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

a:hover {
  color: var(--primary-color);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: var(--heading-color);
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white h1 a,
.text-white h2 a,
.text-white h3 a,
.text-white h4 a,
.text-white h5 a,
.text-white h6 a {
  color: white;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  display: inline-block;
}

mark {
  color: var(--primary-color);
  background: transparent;
  text-decoration: underline;
}

header:after,
section:after,
footer:after {
  display: block;
  clear: both;
  content: "";
}

/*======= Input Styles =======*/
input,
select,
textarea,
.nice-select,
.form-control {
  width: 100%;
  height: auto;
  border: none;
  font-size: 20px;
  border-radius: 0;
  padding: 20px 0;
  background-color: #fff;
  border-bottom: 2px solid rgba(41, 41, 41, 0.1);
}

input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
.nice-select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: var(--heading-color);
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
.nice-select:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: var(--heading-color);
}

input::-ms-input-placeholder,
select::-ms-input-placeholder,
textarea::-ms-input-placeholder,
.nice-select::-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: var(--heading-color);
}

input::placeholder,
select::placeholder,
textarea::placeholder,
.nice-select::placeholder,
.form-control::placeholder {
  color: var(--heading-color);
}

input:focus,
select:focus,
textarea:focus,
.nice-select:focus,
.form-control:focus {
  border-bottom: 2px solid rgba(41, 41, 41, 0.1);
}

.nice-select {
  font-size: 20px;
  line-height: 1.3;
}

.nice-select:after {
  width: 8px;
  height: 8px;
  right: 8px;
  border-color: var(--heading-color);
}

.nice-select .current {
  font-weight: 500;
  color: var(--heading-color);
}

.nice-select .list {
  min-width: 100%;
  border-radius: 0;
}

textarea {
  display: inherit;
  padding-top: 20px;
}

label {
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 5px;
  color: var(--base-color);
}

.form-group {
  position: relative;
  margin-bottom: 15px;
}

input:focus,
button:focus,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #cfdbf1;
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="checkbox"],
input[type="radio"] {
  height: auto;
  width: auto;
}

/* For Scroll Animation */
html {
  scroll-behavior: unset !important;
}

/*******************************************************/
/*****************  ## Common Classes *****************/
/*******************************************************/
.page-wrapper {
  position: relative;
  z-index: 9;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-width: 300px;
}

@media only screen and (min-width: 1200px) {
  .search-filter-inner.rel.z-2{
    height: 430px;
  }
}
  @media only screen and (min-width: 1200px) {
.container {
    max-width: 1320px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .container.container-1720 {
    max-width: 1750px !important;
  }

  .container.container-1550 {
    max-width: 1580px !important;
  }

  .container.container-1130 {
    max-width: 1160px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .container-fluid {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .container-fluid.gap-wide {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

.row {
  --bs-gutter-x: 30px;
}

@media only screen and (min-width: 1200px) {
  .row-cols-xl-7>* {
    width: 14.2857%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

.no-gap {
  margin-left: 0;
  margin-right: 0;
}

.no-gap>div {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 1400px) {
  .gap-10 {
    --bs-gutter-x: 10px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-20 {
    --bs-gutter-x: 20px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-30 {
    --bs-gutter-x: 30px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-40 {
    --bs-gutter-x: 40px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-50 {
    --bs-gutter-x: 50px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-60 {
    --bs-gutter-x: 60px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-70 {
    --bs-gutter-x: 70px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-80 {
    --bs-gutter-x: 80px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-90 {
    --bs-gutter-x: 90px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-100 {
    --bs-gutter-x: 100px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-110 {
    --bs-gutter-x: 110px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-120 {
    --bs-gutter-x: 120px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-130 {
    --bs-gutter-x: 130px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-140 {
    --bs-gutter-x: 140px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-150 {
    --bs-gutter-x: 150px;
  }
}
@media only screen and (max-width: 991px) {

.search-filter-inner.rel.z-2{
  height: 550px;
}
}

@media only screen and (max-width: 375px) {
  .col-small {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/** Section Title style **/
.section-title {
  margin-top: -7px;
}

.section-title .sub-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-color);
  display: block;
}

.section-title h2 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 479px) {
  .section-title h2 {
    font-size: 30px;
    line-height: 1.3;
  }
}

@media only screen and (max-width: 767px) {
  .section-title h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .section-title h3 {
    font-size: 28px;
  }
}

/** Button style **/
.theme-btn,
a.theme-btn {
  z-index: 1;
  color: white;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 500;
  text-align: center;
  padding: 11px 35px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: capitalize;
  background: var(--primary-color);
}

.theme-btn svg,
a.theme-btn svg {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-left: 10px;
}

.theme-btn:hover svg,
a.theme-btn:hover svg {
  margin-left: 13px;
  margin-right: -3px;
}

@media only screen and (max-width: 575px) {

  .theme-btn,
  a.theme-btn {
    padding: 8px 25px;
  }
}

.theme-btn.style-two,
a.theme-btn.style-two {
  color: var(--base-color);
  background: transparent;
  border: 1px solid rgba(41, 41, 41, 0.1);
}

.theme-btn.style-two:hover,
a.theme-btn.style-two:hover {
  color: white;
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.theme-btn.style-three,
a.theme-btn.style-three {
  background: var(--primary-color);
}

/* Details Btn */
.details-btn {
  width: 60px;
  height: 60px;
  background: white;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: var(--primary-color);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media only screen and (max-width: 479px) {
  .details-btn {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

/* Read More */
.read-more {
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-transform: capitalize;
}

.read-more svg {
  float: right;
  font-weight: 400;
  margin-left: 7px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.read-more:hover {
  color: var(--primary-color);
}

.read-more:hover svg {
  margin-left: 10px;
}

/* List style one */
.list-style-one li:not(:last-child) {
  margin-bottom: 8px;
}

/* List style two */
.list-style-two li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.list-style-two li:before {
  content: "\f102";
  font-size: 22px;
  line-height: 1;
  margin-top: 5px;
  margin-right: 12px;
  color: var(--primary-color);
  font-family: "flaticon_merina";
}

.list-style-two li:not(:last-child) {
  margin-bottom: 15px;
}

.list-style-two.three-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.list-style-two.three-column li {
  width: 33%;
}

@media only screen and (max-width: 575px) {
  .list-style-two.three-column li {
    width: 50%;
  }
}

@media only screen and (max-width: 375px) {
  .list-style-two.three-column li {
    width: 100%;
  }
}

/** Social Link One **/
.social-style-one {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: -5px;
  margin-right: -5px;
}

.social-style-one a {
  color: #b0afaf;
  margin-left: 5px;
  margin-right: 5px;
  width: 40px;
  height: 40px;
  background: #0c0b0b;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
}

.social-style-one a:hover {
  color: white;
  background: var(--primary-color);
}

/** Social Link two **/
.social-style-two {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: -10px;
  margin-right: -10px;
}

.social-style-two a {
  color: #e1aa8a;
  margin-left: 10px;
  margin-right: 10px;
}

.social-style-two a:hover {
  color: white;
}

/** Social Link Three **/
.social-style-three {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: -5px;
  margin-right: -5px;
}

.social-style-three a {
  margin-left: 5px;
  margin-right: 5px;
  color: var(--primary-color);
  width: 40px;
  height: 40px;
  background: #f7f0eb;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
}

.social-style-three a:hover {
  color: white;
  background: var(--primary-color);
}

/** Social Link Four **/
.social-style-four {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: -5px;
  margin-right: -5px;
}

.social-style-four a {
  color: var(--base-color);
  margin-left: 5px;
  margin-right: 5px;
  width: 40px;
  height: 40px;
  background: white;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(29, 27, 26, 0.1);
}

.social-style-four a:hover {
  color: white;
  background: #1d1b1a;
}

/*** Preloader style ** */
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../public/newImages/images/preloader.gif);
}

/* Pagination */
.pagination {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
}

.pagination li {
  margin: 10px 15px 0;
}

.pagination li a,
.pagination li .page-link {
  padding: 0;
  border: none;
  font-size: 24px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 600;
  color: var(--base-color);
  background: transparent;
}

.pagination li.active .page-link,
.pagination li:hover:not(.disabled) .page-link {
  background: transparent;
  color: var(--primary-color);
}

/* Rating */
.ratting {
  line-height: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: -5px;
  margin-right: -5px;
}

.ratting svg {
  margin: 5px;
  color: #9f7a39;
}

/*** Slick Arrows ***/
.slick-arrow {
  width: 60px;
  height: 60px;
  color: white;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  background: #303030;
}

.slick-arrow:focus,
.slick-arrow:hover {
  background: white;
  color: var(--heading-color);
}

/*** Slick Dots ***/
.slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slick-dots li {
  position: relative;
  cursor: pointer;
  margin: 8px;
  width: 6px;
  height: 6px;
  opacity: 0.3;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  background: #1d1b1a;
}

.slick-dots li button {
  opacity: 0;
  height: 0;
}

.slick-dots li:before {
  content: "";
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  position: absolute;
  border: 1px solid var(--primary-color);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slick-dots li.slick-active {
  background: var(--primary-color);
  opacity: 1;
}

.slick-dots li.slick-active:before {
  width: 21px;
  height: 21px;
}

/*** Scroll Top style ***/
.scroll-top {
  position: fixed;
  bottom: 25px;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 99;
  color: white;
  display: none;
  font-size: 14px;
  cursor: pointer;
  line-height: 40px;
  border-radius: 5px;
  background: var(--primary-color);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

/* Text White */
.text-white *,
.text-white a,
.text-white .count-text,
.text-white .footer-newsletter-content .sub-title {
  color: white;
}

.text-white li li .dropdown-btn span {
  color: var(--heading-color);
}

.text-white .copyright-area p {
  opacity: 0.5;
}

.text-white .copyright-area a {
  opacity: 0.5;
}

.text-white .copyright-area a:hover {
  opacity: 1;
}

/*Project Filter*/
.filter-btns-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.filter-btns-one li {
  line-height: 1;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 500;
  padding: 13px 35px;
  margin: 0 10px 20px;
  background: var(--lighter-color);
}

@media only screen and (max-width: 375px) {
  .filter-btns-one li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.filter-btns-one li.current {
  color: white;
  background: var(--primary-color);
}

.bg-lines span {
  top: 0;
  z-index: -1;
  width: 1px;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.05);
  -webkit-animation: leftRightOne 18s infinite;
  animation: leftRightOne 18s infinite;
}

.bg-lines span:nth-child(1) {
  left: 10%;
}

.bg-lines span:nth-child(2) {
  left: 20%;
}

.bg-lines span:nth-child(3) {
  left: 30%;
}

.bg-lines span:nth-child(4) {
  left: 40%;
}

.bg-lines span:nth-child(5) {
  left: 50%;
}

.bg-lines span:nth-child(6) {
  left: 60%;
}

.bg-lines span:nth-child(7) {
  left: 70%;
}

.bg-lines span:nth-child(8) {
  left: 80%;
}

.bg-lines span:nth-child(9) {
  left: 90%;
}

.bg-lines.for-bg-white span {
  background: rgba(29, 27, 26, 0.05);
}

/* Position */
.rel {
  position: relative;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.overlay {
  z-index: 1;
  position: relative;
}

.overlay::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.75;
  background-color: var(--heading-color);
}

/* Backgruond Size */
.bgs-cover {
  background-size: cover;
  background-position: center;
}

/* Color + Background */
.bgd-dark {
  background-color: var(--black-color);
}

.bgc-black {
  background-color: #1d1b1a;
}

.bgc-primary {
  background-color: var(--primary-color);
}

.bgc-secondary {
  background-color: var(--primary-color);
}

.bgc-lighter {
  background-color: var(--lighter-color);
}

.color-secondary {
  color: var(--primary-color);
}

.bgc-black-with-lighting {
  background: #1d1b1a;
}

.bgc-black-with-lighting:after,
.bgc-black-with-lighting:before {
  position: absolute;
  width: 450px;
  height: 450px;
  z-index: -1;
  content: "";
  -webkit-filter: blur(110px);
  filter: blur(110px);
  border-radius: 50%;
  background: var(--primary-color);
  -webkit-animation: zoomInOut 5s infinite;
  animation: zoomInOut 5s infinite;
}

@media only screen and (max-width: 1399px) {

  .bgc-black-with-lighting:after,
  .bgc-black-with-lighting:before {
    width: 250px;
    height: 250px;
  }
}

.bgc-black-with-lighting:after {
  right: 25%;
  top: 5%;
}

.bgc-black-with-lighting:before {
  right: 15%;
  bottom: 0;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

/* Border Radius */
.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.br-15 {
  border-radius: 15px;
}

.br-20 {
  border-radius: 20px;
}

.br-25 {
  border-radius: 25px;
}

.br-30 {
  border-radius: 30px;
}

/*******************************************************/
/******************* ## Repeat Style ******************/
/*******************************************************/
.heading,
input,
select,
textarea,
.nice-select,
.form-control,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.accordion-item .accordion-button {
  font-weight: 600;
  color: var(--heading-color);
  font-family: "Cormorant Garamond", serif;
}

h1,
.h1 {
  line-height: 1.31;
  font-size: 65px;
  font-weight: 700;
}

h2,
.h2 {
  line-height: 1.1;
  font-size: 42px;
}

h3,
.h3 {
  line-height: 1.3;
  font-size: 35px;
}

h4,
.h4 {
  line-height: 1.1;
  font-size: 27px;
}

h5,
.h5 {
  line-height: 1.4;
  font-size: 24px;
}

h6,
.h6 {
  font-size: 18px;
}

/*******************************************************/
/************** ## Padding Margin Spacing *************/
/*******************************************************/
/* Padding Around */
.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

.p-55 {
  padding: 55px;
}

.p-60 {
  padding: 60px;
}

.p-65 {
  padding: 65px;
}

.p-70 {
  padding: 70px;
}

.p-75 {
  padding: 75px;
}

.p-80 {
  padding: 80px;
}

.p-85 {
  padding: 85px;
}

.p-90 {
  padding: 90px;
}

.p-95 {
  padding: 95px;
}

.p-100 {
  padding: 100px;
}

.p-105 {
  padding: 105px;
}

.p-110 {
  padding: 110px;
}

.p-115 {
  padding: 115px;
}

.p-120 {
  padding: 120px;
}

.p-125 {
  padding: 125px;
}

.p-130 {
  padding: 130px;
}

.p-135 {
  padding: 135px;
}

.p-140 {
  padding: 140px;
}

.p-145 {
  padding: 145px;
}

.p-150 {
  padding: 150px;
}

.p-155 {
  padding: 155px;
}

.p-160 {
  padding: 160px;
}

.p-165 {
  padding: 165px;
}

.p-170 {
  padding: 170px;
}

.p-175 {
  padding: 175px;
}

.p-180 {
  padding: 180px;
}

.p-185 {
  padding: 185px;
}

.p-190 {
  padding: 190px;
}

.p-195 {
  padding: 195px;
}

.p-200 {
  padding: 200px;
}

.p-205 {
  padding: 205px;
}

.p-210 {
  padding: 210px;
}

.p-215 {
  padding: 215px;
}

.p-220 {
  padding: 220px;
}

.p-225 {
  padding: 225px;
}

.p-230 {
  padding: 230px;
}

.p-235 {
  padding: 235px;
}

.p-240 {
  padding: 240px;
}

.p-245 {
  padding: 245px;
}

.p-250 {
  padding: 250px;
}

/* Padding Top */
.pt-5,
.py-5 {
  padding-top: 5px !important;
}

.pt-10,
.py-10 {
  padding-top: 10px;
}

.pt-15,
.py-15 {
  padding-top: 15px;
}

.pt-20,
.py-20 {
  padding-top: 20px;
}

.pt-25,
.py-25 {
  padding-top: 25px;
}

.pt-30,
.py-30 {
  padding-top: 30px;
}

.pt-35,
.py-35 {
  padding-top: 35px;
}

.pt-40,
.py-40 {
  padding-top: 40px;
}

.pt-45,
.py-45 {
  padding-top: 45px;
}

.pt-50,
.py-50 {
  padding-top: 50px;
}

.pt-55,
.py-55 {
  padding-top: 55px;
}

.pt-60,
.py-60 {
  padding-top: 60px;
}

.pt-65,
.py-65 {
  padding-top: 65px;
}

.pt-70,
.py-70 {
  padding-top: 70px;
}

.pt-75,
.py-75 {
  padding-top: 75px;
}

.pt-80,
.py-80 {
  padding-top: 80px;
}

.pt-85,
.py-85 {
  padding-top: 85px;
}

.pt-90,
.py-90 {
  padding-top: 90px;
}

.pt-95,
.py-95 {
  padding-top: 95px;
}

.pt-100,
.py-100 {
  padding-top: 100px;
}

.pt-105,
.py-105 {
  padding-top: 105px;
}

.pt-110,
.py-110 {
  padding-top: 110px;
}

.pt-115,
.py-115 {
  padding-top: 115px;
}

.pt-120,
.py-120 {
  padding-top: 120px;
}

.pt-125,
.py-125 {
  padding-top: 125px;
}

.pt-130,
.py-130 {
  padding-top: 130px;
}

.pt-135,
.py-135 {
  padding-top: 135px;
}

.pt-140,
.py-140 {
  padding-top: 140px;
}

.pt-145,
.py-145 {
  padding-top: 145px;
}

.pt-150,
.py-150 {
  padding-top: 150px;
}

.pt-155,
.py-155 {
  padding-top: 155px;
}

.pt-160,
.py-160 {
  padding-top: 160px;
}

.pt-165,
.py-165 {
  padding-top: 165px;
}

.pt-170,
.py-170 {
  padding-top: 170px;
}

.pt-175,
.py-175 {
  padding-top: 175px;
}

.pt-180,
.py-180 {
  padding-top: 180px;
}

.pt-185,
.py-185 {
  padding-top: 185px;
}

.pt-190,
.py-190 {
  padding-top: 190px;
}

.pt-195,
.py-195 {
  padding-top: 195px;
}

.pt-200,
.py-200 {
  padding-top: 200px;
}

.pt-205,
.py-205 {
  padding-top: 205px;
}

.pt-210,
.py-210 {
  padding-top: 210px;
}

.pt-215,
.py-215 {
  padding-top: 215px;
}

.pt-220,
.py-220 {
  padding-top: 220px;
}

.pt-225,
.py-225 {
  padding-top: 225px;
}

.pt-230,
.py-230 {
  padding-top: 230px;
}

.pt-235,
.py-235 {
  padding-top: 235px;
}

.pt-240,
.py-240 {
  padding-top: 240px;
}

.pt-245,
.py-245 {
  padding-top: 245px;
}

.pt-250,
.py-250 {
  padding-top: 250px;
}

/* Padding Bottom */
.pb-5,
.py-5 {
  padding-bottom: 5px !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10px;
}

.pb-15,
.py-15 {
  padding-bottom: 15px;
}

.pb-20,
.py-20 {
  padding-bottom: 20px;
}

.pb-25,
.py-25 {
  padding-bottom: 25px;
}

.pb-30,
.py-30 {
  padding-bottom: 30px;
}

.pb-35,
.py-35 {
  padding-bottom: 35px;
}

.pb-40,
.py-40 {
  padding-bottom: 40px;
}

.pb-45,
.py-45 {
  padding-bottom: 45px;
}

.pb-50,
.py-50 {
  padding-bottom: 50px;
}

.pb-55,
.py-55 {
  padding-bottom: 55px;
}

.pb-60,
.py-60 {
  padding-bottom: 60px;
}

.pb-65,
.py-65 {
  padding-bottom: 65px;
}

.pb-70,
.py-70 {
  padding-bottom: 70px;
}

.pb-75,
.py-75 {
  padding-bottom: 75px;
}

.pb-80,
.py-80 {
  padding-bottom: 80px;
}

.pb-85,
.py-85 {
  padding-bottom: 85px;
}

.pb-90,
.py-90 {
  padding-bottom: 90px;
}

.pb-95,
.py-95 {
  padding-bottom: 95px;
}

.pb-100,
.py-100 {
  padding-bottom: 100px;
}

.pb-105,
.py-105 {
  padding-bottom: 105px;
}

.pb-110,
.py-110 {
  padding-bottom: 110px;
}

.pb-115,
.py-115 {
  padding-bottom: 115px;
}

.pb-120,
.py-120 {
  padding-bottom: 120px;
}

.pb-125,
.py-125 {
  padding-bottom: 125px;
}

.pb-130,
.py-130 {
  padding-bottom: 130px;
}

.pb-135,
.py-135 {
  padding-bottom: 135px;
}

.pb-140,
.py-140 {
  padding-bottom: 140px;
}

.pb-145,
.py-145 {
  padding-bottom: 145px;
}

.pb-150,
.py-150 {
  padding-bottom: 150px;
}

.pb-155,
.py-155 {
  padding-bottom: 155px;
}

.pb-160,
.py-160 {
  padding-bottom: 160px;
}

.pb-165,
.py-165 {
  padding-bottom: 165px;
}

.pb-170,
.py-170 {
  padding-bottom: 170px;
}

.pb-175,
.py-175 {
  padding-bottom: 175px;
}

.pb-180,
.py-180 {
  padding-bottom: 180px;
}

.pb-185,
.py-185 {
  padding-bottom: 185px;
}

.pb-190,
.py-190 {
  padding-bottom: 190px;
}

.pb-195,
.py-195 {
  padding-bottom: 195px;
}

.pb-200,
.py-200 {
  padding-bottom: 200px;
}

.pb-205,
.py-205 {
  padding-bottom: 205px;
}

.pb-210,
.py-210 {
  padding-bottom: 210px;
}

.pb-215,
.py-215 {
  padding-bottom: 215px;
}

.pb-220,
.py-220 {
  padding-bottom: 220px;
}

.pb-225,
.py-225 {
  padding-bottom: 225px;
}

.pb-230,
.py-230 {
  padding-bottom: 230px;
}

.pb-235,
.py-235 {
  padding-bottom: 235px;
}

.pb-240,
.py-240 {
  padding-bottom: 240px;
}

.pb-245,
.py-245 {
  padding-bottom: 245px;
}

.pb-250,
.py-250 {
  padding-bottom: 250px;
}

/* Margin Around */
.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.m-35 {
  margin: 35px;
}

.m-40 {
  margin: 40px;
}

.m-45 {
  margin: 45px;
}

.m-50 {
  margin: 50px;
}

.m-55 {
  margin: 55px;
}

.m-60 {
  margin: 60px;
}

.m-65 {
  margin: 65px;
}

.m-70 {
  margin: 70px;
}

.m-75 {
  margin: 75px;
}

.m-80 {
  margin: 80px;
}

.m-85 {
  margin: 85px;
}

.m-90 {
  margin: 90px;
}

.m-95 {
  margin: 95px;
}

.m-100 {
  margin: 100px;
}

.m-105 {
  margin: 105px;
}

.m-110 {
  margin: 110px;
}

.m-115 {
  margin: 115px;
}

.m-120 {
  margin: 120px;
}

.m-125 {
  margin: 125px;
}

.m-130 {
  margin: 130px;
}

.m-135 {
  margin: 135px;
}

.m-140 {
  margin: 140px;
}

.m-145 {
  margin: 145px;
}

.m-150 {
  margin: 150px;
}

.m-155 {
  margin: 155px;
}

.m-160 {
  margin: 160px;
}

.m-165 {
  margin: 165px;
}

.m-170 {
  margin: 170px;
}

.m-175 {
  margin: 175px;
}

.m-180 {
  margin: 180px;
}

.m-185 {
  margin: 185px;
}

.m-190 {
  margin: 190px;
}

.m-195 {
  margin: 195px;
}

.m-200 {
  margin: 200px;
}

.m-205 {
  margin: 205px;
}

.m-210 {
  margin: 210px;
}

.m-215 {
  margin: 215px;
}

.m-220 {
  margin: 220px;
}

.m-225 {
  margin: 225px;
}

.m-230 {
  margin: 230px;
}

.m-235 {
  margin: 235px;
}

.m-240 {
  margin: 240px;
}

.m-245 {
  margin: 245px;
}

.m-250 {
  margin: 250px;
}

/* Margin Top */
.mt-5,
.my-5 {
  margin-top: 5px !important;
}

.mt-10,
.my-10 {
  margin-top: 10px;
}

.mt-15,
.my-15 {
  margin-top: 15px;
}

.mt-20,
.my-20 {
  margin-top: 20px;
}

.mt-25,
.my-25 {
  margin-top: 25px;
}

.mt-30,
.my-30 {
  margin-top: 30px;
}

.mt-35,
.my-35 {
  margin-top: 35px;
}

.mt-40,
.my-40 {
  margin-top: 40px;
}

.mt-45,
.my-45 {
  margin-top: 45px;
}

.mt-50,
.my-50 {
  margin-top: 50px;
}

.mt-55,
.my-55 {
  margin-top: 55px;
}

.mt-60,
.my-60 {
  margin-top: 60px;
}

.mt-65,
.my-65 {
  margin-top: 65px;
}

.mt-70,
.my-70 {
  margin-top: 70px;
}

.mt-75,
.my-75 {
  margin-top: 75px;
}

.mt-80,
.my-80 {
  margin-top: 80px;
}

.mt-85,
.my-85 {
  margin-top: 85px;
}

.mt-90,
.my-90 {
  margin-top: 90px;
}

.mt-95,
.my-95 {
  margin-top: 95px;
}

.mt-100,
.my-100 {
  margin-top: 100px;
}

.mt-105,
.my-105 {
  margin-top: 105px;
}

.mt-110,
.my-110 {
  margin-top: 110px;
}

.mt-115,
.my-115 {
  margin-top: 115px;
}

.mt-120,
.my-120 {
  margin-top: 120px;
}

.mt-125,
.my-125 {
  margin-top: 125px;
}

.mt-130,
.my-130 {
  margin-top: 130px;
}

.mt-135,
.my-135 {
  margin-top: 135px;
}

.mt-140,
.my-140 {
  margin-top: 140px;
}

.mt-145,
.my-145 {
  margin-top: 145px;
}

.mt-150,
.my-150 {
  margin-top: 150px;
}

.mt-155,
.my-155 {
  margin-top: 155px;
}

.mt-160,
.my-160 {
  margin-top: 160px;
}

.mt-165,
.my-165 {
  margin-top: 165px;
}

.mt-170,
.my-170 {
  margin-top: 170px;
}

.mt-175,
.my-175 {
  margin-top: 175px;
}

.mt-180,
.my-180 {
  margin-top: 180px;
}

.mt-185,
.my-185 {
  margin-top: 185px;
}

.mt-190,
.my-190 {
  margin-top: 190px;
}

.mt-195,
.my-195 {
  margin-top: 195px;
}

.mt-200,
.my-200 {
  margin-top: 200px;
}

.mt-205,
.my-205 {
  margin-top: 205px;
}

.mt-210,
.my-210 {
  margin-top: 210px;
}

.mt-215,
.my-215 {
  margin-top: 215px;
}

.mt-220,
.my-220 {
  margin-top: 220px;
}

.mt-225,
.my-225 {
  margin-top: 225px;
}

.mt-230,
.my-230 {
  margin-top: 230px;
}

.mt-235,
.my-235 {
  margin-top: 235px;
}

.mt-240,
.my-240 {
  margin-top: 240px;
}

.mt-245,
.my-245 {
  margin-top: 245px;
}

.mt-250,
.my-250 {
  margin-top: 250px;
}

/* Margin Bottom */
.mb-5,
.my-5 {
  margin-bottom: 5px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10px;
}

.mb-15,
.my-15 {
  margin-bottom: 15px;
}

.mb-20,
.my-20 {
  margin-bottom: 20px;
}

.mb-25,
.my-25 {
  margin-bottom: 25px;
}

.mb-30,
.my-30 {
  margin-bottom: 30px;
}

.mb-35,
.my-35 {
  margin-bottom: 35px;
}

.mb-40,
.my-40 {
  margin-bottom: 40px;
}

.mb-45,
.my-45 {
  margin-bottom: 45px;
}

.mb-50,
.my-50 {
  margin-bottom: 50px;
}

.mb-55,
.my-55 {
  margin-bottom: 55px;
}

.mb-60,
.my-60 {
  margin-bottom: 60px;
}

.mb-65,
.my-65 {
  margin-bottom: 65px;
}

.mb-70,
.my-70 {
  margin-bottom: 70px;
}

.mb-75,
.my-75 {
  margin-bottom: 75px;
}

.mb-80,
.my-80 {
  margin-bottom: 80px;
}

.mb-85,
.my-85 {
  margin-bottom: 85px;
}

.mb-90,
.my-90 {
  margin-bottom: 90px;
}

.mb-95,
.my-95 {
  margin-bottom: 95px;
}

.mb-100,
.my-100 {
  margin-bottom: 100px;
}

.mb-105,
.my-105 {
  margin-bottom: 105px;
}

.mb-110,
.my-110 {
  margin-bottom: 110px;
}

.mb-115,
.my-115 {
  margin-bottom: 115px;
}

.mb-120,
.my-120 {
  margin-bottom: 120px;
}

.mb-125,
.my-125 {
  margin-bottom: 125px;
}

.mb-130,
.my-130 {
  margin-bottom: 130px;
}

.mb-135,
.my-135 {
  margin-bottom: 135px;
}

.mb-140,
.my-140 {
  margin-bottom: 140px;
}

.mb-145,
.my-145 {
  margin-bottom: 145px;
}

.mb-150,
.my-150 {
  margin-bottom: 150px;
}

.mb-155,
.my-155 {
  margin-bottom: 155px;
}

.mb-160,
.my-160 {
  margin-bottom: 160px;
}

.mb-165,
.my-165 {
  margin-bottom: 165px;
}

.mb-170,
.my-170 {
  margin-bottom: 170px;
}

.mb-175,
.my-175 {
  margin-bottom: 175px;
}

.mb-180,
.my-180 {
  margin-bottom: 180px;
}

.mb-185,
.my-185 {
  margin-bottom: 185px;
}

.mb-190,
.my-190 {
  margin-bottom: 190px;
}

.mb-195,
.my-195 {
  margin-bottom: 195px;
}

.mb-200,
.my-200 {
  margin-bottom: 200px;
}

.mb-205,
.my-205 {
  margin-bottom: 205px;
}

.mb-210,
.my-210 {
  margin-bottom: 210px;
}

.mb-215,
.my-215 {
  margin-bottom: 215px;
}

.mb-220,
.my-220 {
  margin-bottom: 220px;
}

.mb-225,
.my-225 {
  margin-bottom: 225px;
}

.mb-230,
.my-230 {
  margin-bottom: 230px;
}

.mb-235,
.my-235 {
  margin-bottom: 235px;
}

.mb-240,
.my-240 {
  margin-bottom: 240px;
}

.mb-245,
.my-245 {
  margin-bottom: 245px;
}

.mb-250,
.my-250 {
  margin-bottom: 250px;
}

.lease_main{
  justify-content: space-around;
}
.lease_box{
  width: 50%;
}
/* Responsive Padding Margin */
@media only screen and (max-width: 1024px) {
  .slider-item-new {
    display: flex;
    justify-content: center;
  }

  .about-area {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 991px) {
  .slider-content {
    padding-top: 50px;
    padding-bottom: 35px;
  }

  /* Padding Around */
  .rp-0 {
    padding: 0px !important;
  }

  .rp-5 {
    padding: 5px !important;
  }

  .rp-10 {
    padding: 10px;
  }

  .rp-15 {
    padding: 15px;
  }

  .rp-20 {
    padding: 20px;
  }

  .rp-25 {
    padding: 25px;
  }

  .rp-30 {
    padding: 30px;
  }

  .rp-35 {
    padding: 35px;
  }

  .rp-40 {
    padding: 40px;
  }

  .rp-45 {
    padding: 45px;
  }

  .rp-50 {
    padding: 50px;
  }

  .rp-55 {
    padding: 55px;
  }

  .rp-60 {
    padding: 60px;
  }

  .rp-65 {
    padding: 65px;
  }

  .rp-70 {
    padding: 70px;
  }

  .rp-75 {
    padding: 75px;
  }

  .rp-80 {
    padding: 80px;
  }

  .rp-85 {
    padding: 85px;
  }

  .rp-90 {
    padding: 90px;
  }

  .rp-95 {
    padding: 95px;
  }

  .rp-100 {
    padding: 100px;
  }

  .rp-105 {
    padding: 105px;
  }

  .rp-110 {
    padding: 110px;
  }

  .rp-115 {
    padding: 115px;
  }

  .rp-120 {
    padding: 120px;
  }

  .rp-125 {
    padding: 125px;
  }

  .rp-130 {
    padding: 130px;
  }

  .rp-135 {
    padding: 135px;
  }

  .rp-140 {
    padding: 140px;
  }

  .rp-145 {
    padding: 145px;
  }

  .rp-150 {
    padding: 150px;
  }

  /* Padding Top */
  .rpt-0,
  .rpy-0 {
    padding-top: 0px !important;
  }

  .rpt-5,
  .rpy-5 {
    padding-top: 5px !important;
  }

  .rpt-10,
  .rpy-10 {
    padding-top: 10px;
  }

  .rpt-15,
  .rpy-15 {
    padding-top: 15px;
  }

  .rpt-20,
  .rpy-20 {
    padding-top: 20px;
  }

  .rpt-25,
  .rpy-25 {
    padding-top: 25px;
  }

  .rpt-30,
  .rpy-30 {
    padding-top: 30px;
  }

  .rpt-35,
  .rpy-35 {
    padding-top: 35px;
  }

  .rpt-40,
  .rpy-40 {
    padding-top: 40px;
  }

  .rpt-45,
  .rpy-45 {
    padding-top: 45px;
  }

  .rpt-50,
  .rpy-50 {
    padding-top: 50px;
  }

  .rpt-55,
  .rpy-55 {
    padding-top: 55px;
  }

  .rpt-60,
  .rpy-60 {
    padding-top: 60px;
  }

  .rpt-65,
  .rpy-65 {
    padding-top: 65px;
  }

  .rpt-70,
  .rpy-70 {
    padding-top: 70px;
  }

  .rpt-75,
  .rpy-75 {
    padding-top: 75px;
  }

  .rpt-80,
  .rpy-80 {
    padding-top: 80px;
  }

  .rpt-85,
  .rpy-85 {
    padding-top: 85px;
  }

  .rpt-90,
  .rpy-90 {
    padding-top: 90px;
  }

  .rpt-95,
  .rpy-95 {
    padding-top: 95px;
  }

  .rpt-100,
  .rpy-100 {
    padding-top: 100px;
  }

  .rpt-105,
  .rpy-105 {
    padding-top: 105px;
  }

  .rpt-110,
  .rpy-110 {
    padding-top: 110px;
  }

  .rpt-115,
  .rpy-115 {
    padding-top: 115px;
  }

  .rpt-120,
  .rpy-120 {
    padding-top: 120px;
  }

  .rpt-125,
  .rpy-125 {
    padding-top: 125px;
  }

  .rpt-130,
  .rpy-130 {
    padding-top: 130px;
  }

  .rpt-135,
  .rpy-135 {
    padding-top: 135px;
  }

  .rpt-140,
  .rpy-140 {
    padding-top: 140px;
  }

  .rpt-145,
  .rpy-145 {
    padding-top: 145px;
  }

  .rpt-150,
  .rpy-150 {
    padding-top: 150px;
  }

  /* Padding Bottom */
  .rpb-0,
  .rpy-0 {
    padding-bottom: 0px !important;
  }

  .rpb-5,
  .rpy-5 {
    padding-bottom: 5px !important;
  }

  .rpb-10,
  .rpy-10 {
    padding-bottom: 10px;
  }

  .rpb-15,
  .rpy-15 {
    padding-bottom: 15px;
  }

  .rpb-20,
  .rpy-20 {
    padding-bottom: 20px;
  }

  .rpb-25,
  .rpy-25 {
    padding-bottom: 25px;
  }

  .rpb-30,
  .rpy-30 {
    padding-bottom: 30px;
  }

  .rpb-35,
  .rpy-35 {
    padding-bottom: 35px;
  }

  .rpb-40,
  .rpy-40 {
    padding-bottom: 40px;
  }

  .rpb-45,
  .rpy-45 {
    padding-bottom: 45px;
  }

  .rpb-50,
  .rpy-50 {
    padding-bottom: 50px;
  }

  .rpb-55,
  .rpy-55 {
    padding-bottom: 55px;
  }

  .rpb-60,
  .rpy-60 {
    padding-bottom: 60px;
  }

  .rpb-65,
  .rpy-65 {
    padding-bottom: 65px;
  }

  .rpb-70,
  .rpy-70 {
    padding-bottom: 70px;
  }

  .rpb-75,
  .rpy-75 {
    padding-bottom: 75px;
  }

  .rpb-80,
  .rpy-80 {
    padding-bottom: 80px;
  }

  .rpb-85,
  .rpy-85 {
    padding-bottom: 85px;
  }

  .rpb-90,
  .rpy-90 {
    padding-bottom: 90px;
  }

  .rpb-95,
  .rpy-95 {
    padding-bottom: 95px;
  }

  .rpb-100,
  .rpy-100 {
    padding-bottom: 100px;
  }

  .rpb-105,
  .rpy-105 {
    padding-bottom: 105px;
  }

  .rpb-110,
  .rpy-110 {
    padding-bottom: 110px;
  }

  .rpb-115,
  .rpy-115 {
    padding-bottom: 115px;
  }

  .rpb-120,
  .rpy-120 {
    padding-bottom: 120px;
  }

  .rpb-125,
  .rpy-125 {
    padding-bottom: 125px;
  }

  .rpb-130,
  .rpy-130 {
    padding-bottom: 130px;
  }

  .rpb-135,
  .rpy-135 {
    padding-bottom: 135px;
  }

  .rpb-140,
  .rpy-140 {
    padding-bottom: 140px;
  }

  .rpb-145,
  .rpy-145 {
    padding-bottom: 145px;
  }

  .rpb-150,
  .rpy-150 {
    padding-bottom: 150px;
  }

  /* Margin Around */
  .rm-0 {
    margin: 0px !important;
  }

  .rm-5 {
    margin: 5px !important;
  }

  .rm-10 {
    margin: 10px;
  }

  .rm-15 {
    margin: 15px;
  }

  .rm-20 {
    margin: 20px;
  }

  .rm-25 {
    margin: 25px;
  }

  .rm-30 {
    margin: 30px;
  }

  .rm-35 {
    margin: 35px;
  }

  .rm-40 {
    margin: 40px;
  }

  .rm-45 {
    margin: 45px;
  }

  .rm-50 {
    margin: 50px;
  }

  .rm-55 {
    margin: 55px;
  }

  .rm-60 {
    margin: 60px;
  }

  .rm-65 {
    margin: 65px;
  }

  .rm-70 {
    margin: 70px;
  }

  .rm-75 {
    margin: 75px;
  }

  .rm-80 {
    margin: 80px;
  }

  .rm-85 {
    margin: 85px;
  }

  .rm-90 {
    margin: 90px;
  }

  .rm-95 {
    margin: 95px;
  }

  .rm-100 {
    margin: 100px;
  }

  .rm-105 {
    margin: 105px;
  }

  .rm-110 {
    margin: 110px;
  }

  .rm-115 {
    margin: 115px;
  }

  .rm-120 {
    margin: 120px;
  }

  .rm-125 {
    margin: 125px;
  }

  .rm-130 {
    margin: 130px;
  }

  .rm-135 {
    margin: 135px;
  }

  .rm-140 {
    margin: 140px;
  }

  .rm-145 {
    margin: 145px;
  }

  .rm-150 {
    margin: 150px;
  }

  /* Margin Top */
  .rmt-0,
  .rmy-0 {
    margin-top: 0px !important;
  }

  .rmt-5,
  .rmy-5 {
    margin-top: 5px !important;
  }

  .rmt-10,
  .rmy-10 {
    margin-top: 10px;
  }

  .rmt-15,
  .rmy-15 {
    margin-top: 15px;
  }

  .rmt-20,
  .rmy-20 {
    margin-top: 20px;
  }

  .rmt-25,
  .rmy-25 {
    margin-top: 25px;
  }

  .rmt-30,
  .rmy-30 {
    margin-top: 30px;
  }

  .rmt-35,
  .rmy-35 {
    margin-top: 35px;
  }

  .rmt-40,
  .rmy-40 {
    margin-top: 40px;
  }

  .rmt-45,
  .rmy-45 {
    margin-top: 45px;
  }

  .rmt-50,
  .rmy-50 {
    margin-top: 50px;
  }

  .rmt-55,
  .rmy-55 {
    margin-top: 55px;
  }

  .rmt-60,
  .rmy-60 {
    margin-top: 60px;
  }

  .rmt-65,
  .rmy-65 {
    margin-top: 65px;
  }

  .rmt-70,
  .rmy-70 {
    margin-top: 70px;
  }

  .rmt-75,
  .rmy-75 {
    margin-top: 75px;
  }

  .rmt-80,
  .rmy-80 {
    margin-top: 80px;
  }

  .rmt-85,
  .rmy-85 {
    margin-top: 85px;
  }

  .rmt-90,
  .rmy-90 {
    margin-top: 90px;
  }

  .rmt-95,
  .rmy-95 {
    margin-top: 95px;
  }

  .rmt-100,
  .rmy-100 {
    margin-top: 100px;
  }

  .rmt-105,
  .rmy-105 {
    margin-top: 105px;
  }

  .rmt-110,
  .rmy-110 {
    margin-top: 110px;
  }

  .rmt-115,
  .rmy-115 {
    margin-top: 115px;
  }

  .rmt-120,
  .rmy-120 {
    margin-top: 120px;
  }

  .rmt-125,
  .rmy-125 {
    margin-top: 125px;
  }

  .rmt-130,
  .rmy-130 {
    margin-top: 130px;
  }

  .rmt-135,
  .rmy-135 {
    margin-top: 135px;
  }

  .rmt-140,
  .rmy-140 {
    margin-top: 140px;
  }

  .rmt-145,
  .rmy-145 {
    margin-top: 145px;
  }

  .rmt-150,
  .rmy-150 {
    margin-top: 150px;
  }

  /* Margin Bottom */
  .rmb-0,
  .rmy-0 {
    margin-bottom: 0px !important;
  }

  .rmb-5,
  .rmy-5 {
    margin-bottom: 5px !important;
  }

  .rmb-10,
  .rmy-10 {
    margin-bottom: 10px;
  }

  .rmb-15,
  .rmy-15 {
    margin-bottom: 15px;
  }

  .rmb-20,
  .rmy-20 {
    margin-bottom: 20px;
  }

  .rmb-25,
  .rmy-25 {
    margin-bottom: 25px;
  }

  .rmb-30,
  .rmy-30 {
    margin-bottom: 30px;
  }

  .rmb-35,
  .rmy-35 {
    margin-bottom: 35px;
  }

  .rmb-40,
  .rmy-40 {
    margin-bottom: 40px;
  }

  .rmb-45,
  .rmy-45 {
    margin-bottom: 45px;
  }

  .rmb-50,
  .rmy-50 {
    margin-bottom: 50px;
  }

  .rmb-55,
  .rmy-55 {
    margin-bottom: 55px;
  }

  .rmb-60,
  .rmy-60 {
    margin-bottom: 60px;
  }

  .rmb-65,
  .rmy-65 {
    margin-bottom: 65px;
  }

  .rmb-70,
  .rmy-70 {
    margin-bottom: 70px;
  }

  .rmb-75,
  .rmy-75 {
    margin-bottom: 75px;
  }

  .rmb-80,
  .rmy-80 {
    margin-bottom: 80px;
  }

  .rmb-85,
  .rmy-85 {
    margin-bottom: 85px;
  }

  .rmb-90,
  .rmy-90 {
    margin-bottom: 90px;
  }

  .rmb-95,
  .rmy-95 {
    margin-bottom: 95px;
  }

  .rmb-100,
  .rmy-100 {
    margin-bottom: 100px;
  }

  .rmb-105,
  .rmy-105 {
    margin-bottom: 105px;
  }

  .rmb-110,
  .rmy-110 {
    margin-bottom: 110px;
  }

  .rmb-115,
  .rmy-115 {
    margin-bottom: 115px;
  }

  .rmb-120,
  .rmy-120 {
    margin-bottom: 120px;
  }

  .rmb-125,
  .rmy-125 {
    margin-bottom: 125px;
  }

  .rmb-130,
  .rmy-130 {
    margin-bottom: 130px;
  }

  .rmb-135,
  .rmy-135 {
    margin-bottom: 135px;
  }

  .rmb-140,
  .rmy-140 {
    margin-bottom: 140px;
  }

  .rmb-145,
  .rmy-145 {
    margin-bottom: 145px;
  }

  .rmb-150,
  .rmy-150 {
    margin-bottom: 150px;
  }

  .about-image-part .top-part {
    justify-content: center;
  }

  .about-image-part .bottom-part {
    justify-content: center;
  }

  .room-div {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .about-image-part .top-part {
    justify-content: center;
  }

  .about-image-part .bottom-part {
    justify-content: center;
  }

}

/*******************************************************/
/***************** ## Custom Animation ****************/
/*******************************************************/
/* Animation Delay */
.delay-1-0s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay-2-0s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.delay-0-1s {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.delay-0-2s {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay-0-3s {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.delay-0-4s {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay-0-5s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-0-6s {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay-0-7s {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.delay-0-8s {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.delay-0-9s {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.delay-1-1s {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.delay-1-2s {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.delay-1-3s {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.delay-1-4s {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.delay-1-5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.delay-1-6s {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.delay-1-7s {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.delay-1-8s {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.delay-1-9s {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

/* Menu Sticky */
@-webkit-keyframes sticky {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

/* Hero Circle */
@-webkit-keyframes rotated_circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotated_circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Feedback Rotated */
@-webkit-keyframes semi_rotated {

  0%,
  100% {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  50% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

@keyframes semi_rotated {

  0%,
  100% {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  50% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

/* Image BG Rotated */
@-webkit-keyframes semi_rotated_two {

  0%,
  100% {
    -webkit-transform: rotate(-11deg);
    transform: rotate(-11deg);
  }

  50% {
    -webkit-transform: rotate(11deg);
    transform: rotate(11deg);
  }
}

@keyframes semi_rotated_two {

  0%,
  100% {
    -webkit-transform: rotate(-11deg);
    transform: rotate(-11deg);
  }

  50% {
    -webkit-transform: rotate(11deg);
    transform: rotate(11deg);
  }
}

@-webkit-keyframes move_arround {
  0% {
    top: 20px;
    left: 20px;
  }

  25% {
    top: 20px;
    left: -20px;
  }

  50% {
    top: -20px;
    left: -20px;
  }

  75% {
    top: -20px;
    left: 20px;
  }

  100% {
    top: 20px;
    left: 20px;
  }
}

@keyframes move_arround {
  0% {
    top: 20px;
    left: 20px;
  }

  25% {
    top: 20px;
    left: -20px;
  }

  50% {
    top: -20px;
    left: -20px;
  }

  75% {
    top: -20px;
    left: 20px;
  }

  100% {
    top: 20px;
    left: 20px;
  }
}

/* Hero Circle */
@-webkit-keyframes upDownLeft {

  0%,
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  25%,
  75% {
    -webkit-transform: translate(0px, 50px);
    transform: translate(0px, 50px);
  }

  50% {
    -webkit-transform: translate(-50px, 50px);
    transform: translate(-50px, 50px);
  }
}

@keyframes upDownLeft {

  0%,
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  25%,
  75% {
    -webkit-transform: translate(0px, 50px);
    transform: translate(0px, 50px);
  }

  50% {
    -webkit-transform: translate(-50px, 50px);
    transform: translate(-50px, 50px);
  }
}

@-webkit-keyframes shapeAnimationOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 0px) rotate(270deg);
    transform: translate(150px, 0px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 0px) rotate(270deg);
    transform: translate(150px, 0px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px, 0px) rotate(270deg);
    transform: translate(-150px, 0px) rotate(270deg);
  }

  50% {
    -webkit-transform: translate(-150px, 150px) rotate(180deg);
    transform: translate(-150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px, 0px) rotate(270deg);
    transform: translate(-150px, 0px) rotate(270deg);
  }

  50% {
    -webkit-transform: translate(-150px, 150px) rotate(180deg);
    transform: translate(-150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationThree {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(50px, 150px) rotate(90deg);
    transform: translate(50px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 50px) rotate(270deg);
    transform: translate(150px, 50px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationThree {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(50px, 150px) rotate(90deg);
    transform: translate(50px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 50px) rotate(270deg);
    transform: translate(150px, 50px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationFour {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px -50px) rotate(90deg);
    transform: translate(-150px -50px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(-50px, -150px) rotate(270deg);
    transform: translate(-50px, -150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationFour {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px -50px) rotate(90deg);
    transform: translate(-150px -50px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(-50px, -150px) rotate(270deg);
    transform: translate(-50px, -150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationFive {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-100px -100px) rotate(90deg);
    transform: translate(-100px -100px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(100px, 50px) rotate(180deg);
    transform: translate(100px, 50px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(-100px, 150px) rotate(270deg);
    transform: translate(-100px, 150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationFive {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-100px -100px) rotate(90deg);
    transform: translate(-100px -100px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(100px, 50px) rotate(180deg);
    transform: translate(100px, 50px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(-100px, 150px) rotate(270deg);
    transform: translate(-100px, 150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes down-up-one {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translateY(25px);
    transform: rotateX(0deg) translateY(25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@keyframes down-up-one {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translateY(25px);
    transform: rotateX(0deg) translateY(25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@-webkit-keyframes down-up-two {
  0% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translate(25px, -25px);
    transform: rotateX(0deg) translate(25px, -25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }
}

@keyframes down-up-two {
  0% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translate(25px, -25px);
    transform: rotateX(0deg) translate(25px, -25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }
}

@-webkit-keyframes leftRightOne {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}

@keyframes leftRightOne {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}

@-webkit-keyframes leftRightTwo {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

@keyframes leftRightTwo {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

@-webkit-keyframes zoomInOut {

  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@keyframes zoomInOut {

  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@-webkit-keyframes border {
  0% {
    width: 0;
    height: 0;
  }

  50% {
    width: 0;
    height: 100%;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

@keyframes border {
  0% {
    width: 0;
    height: 0;
  }

  50% {
    width: 0;
    height: 100%;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

/*******************************************************/
/******************* ## Header style ******************/
/*******************************************************/
.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header .container-fluid {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 1200px) {
  .main-header .container-fluid {
    padding-left: 55px;
    padding-right: 55px;
  }
}

.main-header .header-upper {
  z-index: 5;
  width: 100%;
  position: relative;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header .logo-outer {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

@media only screen and (max-width: 991px) {
  .main-header .logo-outer {
    display: none;
  }

  .slider-content {
    padding-top: 50px;
    padding-bottom: 35px;
  }
}

.main-header .logo {
  z-index: 9;
  padding: 2px 0;
  position: relative;
  margin-right: 25px;
}

.main-header .nice-select .current {
  color: white;
}

.main-header.menu-absolute .header-upper {
  position: absolute;
}

.main-header.fixed-header .header-upper {
  top: 0;
  left: 0;
  position: fixed;
  background: white;
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
}

@media only screen and (min-width: 992px) {
  .main-header.fixed-header .main-menu .navbar-collapse>ul>li {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .header-top-wrap {
    display: none;
  }
}

.header-top .top-left>ul,
.header-top .top-right>ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 991px) {

  .header-top .top-left>ul,
  .header-top .top-right>ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .slider-content {
    padding-top: 50px;
    padding-bottom: 35px;
  }
}

.header-top .top-left>ul>li,
.header-top .top-right>ul>li {
  color: white;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.header-top .top-left>ul>li>i,
.header-top .top-right>ul>li>i {
  font-size: 18px;
  margin-right: 10px;
}

.header-top .top-left>ul>li>a,
.header-top .top-right>ul>li>a {
  color: white;
}

.header-top .top-left>ul>li:not(:last-child):after,
.header-top .top-right>ul>li:not(:last-child):after {
  content: "";
  margin-left: 40px;
  margin-right: 40px;
  width: 5px;
  height: 5px;
  background: white;
  line-height: 5px;
  border-radius: 50%;
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .header-top .top-right>ul {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.nav-outer {
  margin-left: auto;
}

@media only screen and (max-width: 991px) {
  .nav-outer {
    width: 100%;
  }
}

/** Header Main Menu **/
@media only screen and (max-width: 991px) {
  .main-menu {
    width: 100%;
    /* width: 96%; */
  }

  .slider-content {
    padding-top: 50px;
    padding-bottom: 35px;
  }
}

.main-menu .mobile-logo {
  margin-right: auto;
}

@media only screen and (max-width: 575px) {
  .main-menu .mobile-logo {
    max-width: 150px;
    /*margin-left: 50%;*/
  }
}

@media only screen and (max-width: 991px) {
  .main-menu .collapse {
    overflow: auto;
  }
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navbar-collapse>ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse>ul {
    width: 100%;
    display: block;
    padding: 25px 0;
    overflow-x: hidden;
    background: #ffffff;
    max-height: calc(100vh - 100px);
  }

  .main-menu .navbar-collapse>ul>li:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse {
    left: 0;
    width: 100%;
    position: absolute;
  }
}

.main-menu .navbar-collapse li {
  padding: 35px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 1399px) {
  .main-menu .navbar-collapse li {
    padding-left: 20px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li {
    display: block;
    padding: 0 15px;
    border-top: 1px solid #f3f3f3;
  }
}

.main-menu .navbar-collapse li.dropdown .dropdown-btn {
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
  color: var(--heading-color);
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 0;
    width: 50px;
    height: 43px;
    border-left: 1px solid #f2f2f2;
    text-align: center;
    line-height: 43px;
  }
}

.main-menu .navbar-collapse li a {
  display: block;
  font-size: 18px;
  opacity: 1;
  font-weight: 500;
  position: relative;
  color: var(--heading-color);
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li a {
    padding: 10px 10px;
    line-height: 22px;
  }
}

.main-menu .navbar-collapse li a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.main-menu .navbar-collapse li.current>a,
.main-menu .navbar-collapse li.current-menu-item>a {
  font-weight: 500;
}

.main-menu .navbar-collapse li li {
  border-top: 1px solid #f3f3f3;
}

.main-menu .navbar-collapse li li a {
  text-transform: capitalize;
}

.main-menu .navbar-collapse li li a:before {
  display: none;
}

.main-menu .navbar-collapse li .megamenu {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 100%;
  z-index: 100;
  display: none;
  padding: 20px 0;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navbar-collapse li .megamenu:after {
  display: block;
  clear: both;
  content: "";
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li .megamenu {
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
  }

  .main-menu .navbar-collapse li .megamenu .container {
    max-width: 100% !important;
  }

  .main-menu .navbar-collapse li .megamenu .row {
    margin: 0px;
  }
}

.main-menu .navbar-collapse li .megamenu ul {
  display: block;
  position: relative;
  top: 0;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.main-menu .navbar-collapse li ul {
  position: absolute;
  left: inherit;
  top: 100%;
  min-width: 250px;
  z-index: 100;
  display: none;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul {
    position: relative;
    display: none;
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .main-menu .navbar-collapse li ul:after {
    display: block;
    clear: both;
    content: "";
  }
}

@media only screen and (max-width: 991px) and (max-width: 375px) {
  .main-menu .navbar-collapse li ul {
    min-width: auto;
  }
}

.main-menu .navbar-collapse li ul li {
  width: 100%;
  padding: 7px 20px;
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul li {
    padding: 0 15px;
  }
}

.main-menu .navbar-collapse li ul li ul {
  left: 100%;
  top: 0%;
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul li ul {
    left: auto;
  }
}

.main-menu .navbar-header {
  display: none;
}

/* @media only screen and (max-width: 991px) {
  .main-menu .navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
} */

@media only screen and (max-width: 991px) {
  .main-menu .navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.main-menu .navbar-header .navbar-toggle {
  float: right;
  padding: 4px 0;
  cursor: pointer;
  background: transparent;
}

.main-menu .navbar-header .navbar-toggle .icon-bar {
  background: var(--base-color);
  height: 2px;
  width: 30px;
  display: block;
  margin: 7px 0;
}

/* Menu Btns */
.menu-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu-btns .theme-btn {
  margin-left: 35px;
}

@media only screen and (max-width: 1199px) {
  .menu-btns .theme-btn {
    display: none;
  }
}

.shopping-bag {
  line-height: 1;
  margin-left: 40px;
  position: relative;
  background: transparent;
}

@media only screen and (max-width: 991px) {
  .shopping-bag {
    display: none;
  }
}

.shopping-bag span {
  position: absolute;
  right: -10px;
  top: -10px;
}

.menu-phone-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 1399px) {
  .menu-phone-number {
    display: none;
  }
}

.menu-phone-number i {
  font-size: 35px;
  margin-right: 15px;
  color: var(--primary-color);
}

.menu-phone-number a {
  font-size: 22px;
  font-weight: 500;
}

/* Lnaguage Switcher */
.language-switcher .nice-select {
  border: none;
  border-radius: 0;
  padding: 10px 28px 10px 10px;
  background-color: transparent;
}

.language-switcher .nice-select:after {
  width: 7px;
  height: 7px;
  right: 10px;
  margin-top: -6px;
  border-color: white;
}

/* Menu Sidebar */
.menu-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 40px;
}

@media only screen and (max-width: 575px) {
  .menu-sidebar {
    margin-left: 20px;
  }
}

.menu-sidebar button {
  width: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: transparent;
}

.menu-sidebar button .icon-bar {
  background: var(--heading-color);
  height: 2px;
  width: 100%;
  margin: 4px 0;
  display: block;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.menu-sidebar button .icon-bar:first-child {
  width: calc(100% - 5px);
}

.menu-sidebar button .icon-bar:last-child {
  width: calc(100% - 8px);
}

.menu-sidebar button:hover .icon-bar {
  width: 100%;
}

/** hidden-sidebar * */
.hidden-bar {
  position: fixed;
  right: -350px;
  top: 0px;
  opacity: 0;
  width: 350px;
  height: 100%;
  z-index: 99999;
  overflow-y: auto;
  visibility: hidden;
  background-color: var(--heading-color);
  border-left: 1px solid #231b26;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 375px) {
  .hidden-bar {
    width: 300px;
  }
}

.hidden-bar .social-style-one a {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.side-content-visible .hidden-bar {
  right: 0px;
  opacity: 1;
  visibility: visible;
}

.hidden-bar .inner-box {
  position: relative;
  padding: 100px 40px 50px;
}

@media only screen and (max-width: 375px) {
  .hidden-bar .inner-box {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.hidden-bar .inner-box .cross-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.hidden-bar .inner-box h4 {
  position: relative;
  color: #ffffff;
  margin-bottom: 35px;
}

/*Appointment Form */
.hidden-bar .appointment-form {
  position: relative;
}

.hidden-bar .appointment-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.hidden-bar .appointment-form input[type="text"],
.hidden-bar .appointment-form input[type="email"] {
  position: relative;
  display: block;
  width: 100%;
  line-height: 23px;
  padding: 10px 25px;
  color: #ffffff;
  font-size: 16px;
  background: none;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.hidden-bar .appointment-form textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 23px;
  padding: 10px 25px;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: none;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  resize: none;
}

.hidden-bar .appointment-form input::-webkit-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form input:-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form input::-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form input::placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::-webkit-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea:-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form .form-group button {
  width: 100%;
  font-size: 16px;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-color: white;
}

.form-back-drop {
  position: fixed;
  left: 100%;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  z-index: 9990;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.side-content-visible .form-back-drop {
  opacity: 1;
  left: 0;
  visibility: visible;
}

/* Menu White */
.main-header.header-white {
  position: absolute;
}

/* @media only screen and (min-width: 1200px) {
  .main-header.header-white .nav-outer {
    margin-left: 110px;
  }
} */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-header.header-white .nav-outer {
    margin-left: 60px;
  }
}

.main-header.header-white .shopping-bag,
.main-header.header-white .menu-btns>button,
.main-header.header-white .nav-search>button,
.main-header.header-white .menu-phone-number a {
  color: white;
}

@media only screen and (min-width: 992px) {

  .main-header.header-white .main-menu .navigation>li>a,
  .main-header.header-white .main-menu .navigation>.dropdown>.dropdown-btn {
    color: white;
  }
}

.main-header.header-white .main-menu .navigation li li a:hover {
  color: var(--primary-color);
}

.main-header.header-white .menu-sidebar button .icon-bar,
.main-header.header-white .main-menu .navbar-toggle .icon-bar {
  background: white;
}

@media only screen and (max-width: 1299px) {
  .main-header.header-white .language-switcher {
    display: none;
  }
}

.main-header.header-white.fixed-header .header-upper {
  background: #17171a;
  /*padding-bottom: 2%;*/
}

/*******************************************************/
/******************* ## Page Banner *******************/
/*******************************************************/
.page-banner-area {
  z-index: 1;
  position: relative;
}

.page-banner-area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.75;
  background-color: #1d1b1a;
}

.page-banner-area:before {
  mix-blend-mode: multiply;
}

.banner-inner h1 {
  font-size: 85px;
  font-weight: 400;
  text-transform: capitalize;
}

@media only screen and (max-width: 1399px) {
  .banner-inner h1 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-inner h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-inner h1 {
    font-size: 55px;
  }

  .about-image-part .top-part {
    justify-content: center;
  }

  .about-image-part .bottom-part {
    justify-content: center;
  }

}

@media only screen and (max-width: 575px) {
  .banner-inner h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-inner h1 {
    font-size: 35px;
    line-height: 1.25;
  }
}

.breadcrumb {
  padding: 0;
  margin: 0;
  font-size: 22px;
  background: transparent;
  text-transform: capitalize;
}

@media only screen and (max-width: 991px) {
  .breadcrumb {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .breadcrumb {
    font-size: 18px;
  }
}

@media only screen and (max-width: 375px) {
  .breadcrumb {
    font-size: 16px;
  }
}

.breadcrumb .breadcrumb-item {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.breadcrumb .breadcrumb-item a {
  color: white;
}

.breadcrumb .breadcrumb-item.active {
  color: white;
  text-decoration: underline;
}

.breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  margin-left: 25px;
  margin-right: 25px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  content: "";
  width: 5px;
  height: 5px;
  padding-right: 0;
  border-radius: 50%;
  background: white;
}

/* Banner Shapes */
.banner-shapes .circle {
  width: 770px;
  height: 770px;
  z-index: -2;
  top: -300px;
  left: -200px;
  background: white;
  border-radius: 50%;
  position: absolute;
}

@media only screen and (max-width: 991px) {
  .banner-shapes .circle {
    width: 400px;
    height: 400px;
  }
}

.banner-shapes .shape-one,
.banner-shapes .shape-two {
  z-index: -1;
  max-width: 10%;
  position: absolute;
}

.banner-shapes .shape-one {
  left: 6%;
  top: 35%;
  -webkit-animation: down-up-two 15s infinite;
  animation: down-up-two 15s infinite;
}

.banner-shapes .shape-two {
  right: 6%;
  top: 65%;
  -webkit-animation: upDownLeft 20s infinite;
  animation: upDownLeft 20s infinite;
}

.banner-shapes:after,
.banner-shapes:before {
  position: absolute;
  border: 2px solid;
  content: "";
  width: 15px;
  height: 15px;
  z-index: -1;
  border-radius: 50%;
}

.banner-shapes:before {
  left: 10%;
  top: 65%;
  border-color: var(--primary-color);
  -webkit-animation: shapeAnimationOne 30s infinite;
  animation: shapeAnimationOne 30s infinite;
}

.banner-shapes:after {
  right: 10%;
  top: 20%;
  border-color: var(--primary-color);
  -webkit-animation: shapeAnimationTwo 30s infinite;
  animation: shapeAnimationTwo 30s infinite;
}

/*******************************************************/
/******************* ## Main Slider ********************/
/*******************************************************/
.main-slider-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-top: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (min-width: 1200px) {
  .main-slider-area .container {
    max-width: 1750pxc !important;
  }
}

.main-slider-area .slick-dots li {
  background: white;
}

.main-slider-area .slick-dots li:before {
  border-color: white;
}

.main-slider-active {
  width: 100%;
}

.main-slider-active .slick-list {
  overflow: visible;
}

.slider-item {
  padding-top: 100px;
  padding-bottom: 100px;
}
img.w-\[420px\].h-\[300px\].object-cover.rounded-none{
  height: 270px;
}
@media only screen and (max-width: 991px) {
  .slider-item {
    padding-top: 60px;
  }
  .navbar-header.flex.items-center.md\:hidden{
    padding-right: 25px;
  }
}

.main-slider-dots {
  z-index: 2;
  position: relative;
  margin-top: -155px;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

@media only screen and (min-width: 1600px) {
  .main-slider-dots {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 1399px) {
  .main-slider-dots {
    margin-top: -135px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider-dots {
    margin-top: -145px;
    padding-left: 30px;
  }
}

.main-slider-dots .slick-dots li {
  border-color: white;
}

.main-slider-dots .slick-dots li.slick-active {
  background: white;
}

.slider-content {
  z-index: 2;
  position: relative;
  padding-top: 55px;
  padding-bottom: 55px;
}

@media only screen and (min-width: 1600px) {
  .slider-content {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 992px) {
  .slider-content {
    margin-right: -2px;
  }
}

@media only screen and (max-width: 1199px) {
  .slider-content {
    padding-top: 0;
    padding-bottom: 75px;
  }
}

.slider-content .sub-title {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  display: inline-block;
  font-family: "Catamaran", sans-serif;
}

.slider-content .sub-title svg {
  width: 55px;
  height: 30px;
  line-height: 30px;
  margin-right: 25px;
  text-align: center;
  border-radius: 15px;
  background: var(--primary-color);
}

.slider-content h1 {
  color: white;
  margin-bottom: 40px;
}

.slider-content h1 span {
  font-weight: 400;
  font-style: italic;
}

.Explore_texts {
  text-align: right;
}

@media only screen and (max-width: 991px) {
  .slider-content {
    padding-top: 50px;
  }

  .Explore_texts {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content h1 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-content h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 375px) {
  .slider-content h1 {
    font-size: 40px;
  }
}

.slider-image,
.slider-content>* {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slick-active .slider-content * {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.slick-active .sub-title {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.slick-active h1 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.slick-active .theme-btn {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.slick-active .slider-image {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.slider-shapes .shape {
  z-index: -1;
  position: absolute;
}

.slider-shapes .circle-half {
  top: 25%;
  left: 5%;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  -webkit-animation: rotated_circle 30s infinite;
  animation: rotated_circle 30s infinite;
}

.slider-shapes .circle {
  left: 5%;
  bottom: 20%;
  -webkit-animation: shapeAnimationThree 40s infinite;
  animation: shapeAnimationThree 40s infinite;
}

.slider-shapes:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 565px;
  height: 500px;
  border-radius: 0 500px 0 0;
  -webkit-animation: leftRightOne 22s infinite;
  animation: leftRightOne 22s infinite;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(13.33%, rgba(171, 96, 52, 0)),
      to(rgba(171, 96, 52, 0.2)));
  background: -webkit-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: -o-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: linear-gradient(180deg,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
}

@media only screen and (max-width: 1399px) {
  .slider-shapes:before {
    width: 262px;
    height: 250px;
  }
}

.slider-shapes:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 455px;
  height: 400px;
  border-radius: 0 400px 0 0;
  -webkit-animation: leftRightOne 22s infinite;
  animation: leftRightOne 22s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(13.33%, rgba(171, 96, 52, 0)),
      to(rgba(171, 96, 52, 0.2)));
  background: -webkit-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: -o-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: linear-gradient(180deg,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
}

@media only screen and (max-width: 1399px) {
  .slider-shapes:after {
    width: 222px;
    height: 200px;
  }
}

/* Main Slider Two */
.hero-slider-two {
  background: var(--black-color);
}

.hero-slider-two .bg-lines span {
  z-index: 0;
  background: rgba(255, 255, 255, 0.07);
}

.slider-two-active .slider-item-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slider-two-active .slick-arrow {
  top: 50%;
  z-index: 2;
  left: 100px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 575px) {
  .slider-two-active .slick-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .slider-two-active .slick-arrow {
    left: 15px;
  }
}

.slider-two-active .slick-arrow.next-arrow {
  left: auto;
  right: 100px;
}

@media only screen and (max-width: 991px) {
  .slider-two-active .slick-arrow.next-arrow {
    right: 15px;
  }
}

.slider-item-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 150px;
  background-size: cover;
  z-index: 1;
  position: relative;
  background-position: center;
}

.slider-item-two::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.85;
  background-color: #1d1b1a;
}

@media only screen and (min-width: 992px) {
  .slider-item-two {
    min-height: 100vh;
  }
}

.slider-item-two:before {
  mix-blend-mode: multiply;
}

.slider-item-two:after {
  z-index: -1;
  content: "";
  width: 415px;
  height: 415px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-filter: blur(125px);
  filter: blur(125px);
  background: var(--primary-color);
}

@media only screen and (max-width: 991px) {
  .slider-item-two:after {
    width: 200px;
    height: 200px;
    -webkit-filter: blur(80px);
    filter: blur(80px);
  }
}

.slider-content-two {
  z-index: 5;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
}

.slider-content-two h1 {
  color: white;
  line-height: 1.1;
  margin-bottom: 25px;
}

.slider-content-two h1 i {
  font-weight: 300;
}

@media only screen and (min-width: 1400px) {
  .slider-content-two h1 {
    font-size: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-two h1 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-content-two h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 375px) {
  .slider-content-two h1 {
    font-size: 40px;
  }
}

.slider-content-two p {
  color: white;
  margin-bottom: 40px;
}

@media only screen and (min-width: 376px) {
  .slider-content-two p {
    font-size: 18px;
  }
}

.slider-content-two>* {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slick-active .slider-content-two * {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.slick-active h1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.slick-active p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.slick-active .theme-btn {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.hero-left-shapes:after,
.hero-left-shapes:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  opacity: 0.55;
  background: var(--primary-color);
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}

.hero-left-shapes:before {
  width: 310px;
  height: 295px;
}

@media only screen and (max-width: 991px) {
  .hero-left-shapes:before {
    width: 165px;
    height: 155px;
  }
}

.hero-left-shapes:after {
  width: 410px;
  height: 390px;
}

@media only screen and (max-width: 991px) {
  .hero-left-shapes:after {
    width: 250px;
    height: 235px;
  }
}

.hero-right-shapes:after,
.hero-right-shapes:before {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  opacity: 0.55;
  background: var(--primary-color);
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0);
  clip-path: polygon(0 100%, 100% 100%, 100% 0);
}

.hero-right-shapes:before {
  width: 165px;
  height: 155px;
}

@media only screen and (max-width: 991px) {
  .hero-right-shapes:before {
    width: 85px;
    height: 75px;
  }
}

.hero-right-shapes:after {
  width: 250px;
  height: 235px;
}

@media only screen and (max-width: 991px) {
  .hero-right-shapes:after {
    width: 125px;
    height: 115px;
  }
}

/*******************************************************/
/******************** ## Hero Section ******************/
/*******************************************************/
.hero-area {
  z-index: 1;
  position: relative;
}

.hero-area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.85;
  background-color: #1d1b1a;
}

.hero-area:before {
  mix-blend-mode: multiply;
}

.hero-area .video-play {
  margin-bottom: 30px;
}

.hero-area h1 {
  line-height: 1.1;
  margin-bottom: 30px;
}

.hero-area h1 i {
  font-weight: 400;
}

@media only screen and (min-width: 1200px) {
  .hero-area h1 {
    font-size: 85px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-area h1 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-area h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 375px) {
  .hero-area h1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 576px) {
  .hero-area p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) {
  .hero-area .booking-search {
    margin-left: 100px;
  }
}

.hero-area .booking-search:before {
  display: none;
}

.hero-area .bg-lines span {
  background: rgba(255, 255, 255, 0.1);
}

/*******************************************************/
/******************** ## About Section *****************/
/*******************************************************/
.about-image-part {
  max-width: 535px;
}

.about-image-part img {
  width: 60%;
}

/* .about-image-part .top-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
} */
.about-image-part .top-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.about-image-part .top-part img {
  margin-right: 3%;
}

.about-image-part .top-part .icon {
  color: white;
  font-size: 65px;
  margin-bottom: 19%;
  width: 130px;
  height: 130px;
  background: var(--primary-color);
  line-height: 130px;
  border-radius: 50%;
  text-align: center;
  -webkit-box-shadow: 0px 10px 50px rgba(171, 96, 52, 0.5);
  box-shadow: 0px 10px 50px rgba(171, 96, 52, 0.5);
}

@media only screen and (max-width: 575px) {
  .about-image-part .top-part .icon {
    width: 80px;
    height: 80px;
    font-size: 35px;
    line-height: 80px;
  }
}

.about-image-part .bottom-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -16%;
}

.about-image-part .bottom-part .video-play-text {
  position: relative;
  margin: 22% -10% 0 10%;
}

@media only screen and (min-width: 1400px) {
  .about-image-part .bottom-part .video-play-text {
    min-width: 210px;
  }
}

@media only screen and (max-width: 375px) {
  .about-image-part .bottom-part .video-play-text {
    margin-left: 5%;
    margin-right: -22%;
  }
}

.about-content-part .feature-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 15px 5px;
  background: var(--lighter-color);
}

@media only screen and (max-width: 575px) {
  .about-content-part .feature-list {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .about-content-part .feature-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.about-content-part .feature-list .feature-item {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 767px) {
  .about-content-part .feature-list .feature-item {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.about-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 530px;
  padding-top: 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-author .author {
  width: 70px;
  height: 70px;
  margin-top: 25px;
  border-radius: 50%;
  margin-right: 25px;
}

@media only screen and (max-width: 375px) {
  .about-author .author {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.about-author .description {
  margin-top: 25px;
  margin-right: auto;
}

.about-author .description h5 {
  margin-bottom: 0;
  line-height: 1.1;
}

@media only screen and (max-width: 375px) {
  .about-author .description h5 {
    font-size: 20px;
  }
}

.about-author .description span {
  font-size: 14px;
}

.about-author .signature {
  margin-top: 25px;
}

/* About Three */
.about-three-image-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.about-three-image-part .first-image {
  width: 30%;
  z-index: 2;
  position: relative;
}

.about-three-image-part .middle-image {
  width: 70%;
  z-index: 1;
  margin-top: 6%;
  margin-left: -12%;
  padding-bottom: 12%;
  position: relative;
}

.about-three-image-part .middle-image img {
  border-radius: 50%;
}

.about-three-image-part .last-image {
  width: 30%;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  margin-left: -18%;
}

.about-three-image-part img {
  width: 100%;
}

.about-three-image-part .video-play {
  position: absolute;
  right: 10%;
  top: 10%;
  z-index: 3;
  color: white;
  font-size: 16px;
  border-radius: 0;
}

@media only screen and (min-width: 576px) {
  .about-three-image-part .video-play {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .about-three-image-part .video-play {
    top: 5%;
    right: 5%;
  }
}

@media only screen and (min-width: 1400px) {
  .about-content-three {
    padding-left: 100px;
  }
}

.about-content-three .feature-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about-content-three .feature-list .feature-item {
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 480px) {
  .about-content-three .feature-list .feature-item {
    width: 48%;
  }
}

/* About Page */
.about-page-right {
  padding: 65px 60px 1px;
}

@media only screen and (max-width: 767px) {
  .about-page-right {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .about-page-right .feature-item.style-two {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .about-page-right .feature-item.style-two .icon {
    margin-bottom: 25px;
  }
}

/*******************************************************/
/******************* ## Contact Section ****************/
/*******************************************************/
.location-tab li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.location-tab li:not(:last-child):after {
  content: "";
  width: 5px;
  height: 5px;
  opacity: 0.2;
  border-radius: 50%;
  margin-left: 40px;
  margin-right: 40px;
  background: var(--heading-color);
}

@media only screen and (max-width: 575px) {
  .location-tab li:not(:last-child):after {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.location-tab li a {
  font-size: 35px;
  position: relative;
  padding-bottom: 15px;
  font-family: "Cormorant Garamond", serif;
}

@media only screen and (max-width: 375px) {
  .location-tab li a {
    font-size: 25px;
  }
}

.location-tab li a:after {
  opacity: 0;
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  -webkit-transition: 0.5;
  -o-transition: 0.5;
  transition: 0.5;
  width: 45px;
  height: 3px;
  background: var(--primary-color);
}

.location-tab li a.active {
  font-weight: 700;
  color: var(--heading-color);
}

.location-tab li a.active:after {
  opacity: 1;
}

.contact-info-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
}

.contact-info-item .icon {
  width: 50px;
  font-size: 50px;
  margin-right: 30px;
  color: var(--primary-color);
}

.contact-info-item .content .title {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--heading-color);
}

.contact-info-item .content .text {
  line-height: 1.5;
}

@media only screen and (min-width: 376px) {
  .contact-info-item .content .text {
    font-size: 20px;
  }
}

.contact-info-item:not(:last-child) {
  margin-bottom: 40px;
}

.contact-page-form {
  padding: 50px;
  background: var(--lighter-color);
}

@media only screen and (max-width: 479px) {
  .contact-page-form {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.contact-page-form .form-group {
  margin-bottom: 20px;
}

.contact-page-form .form-group .nice-select,
.contact-page-form .form-group .form-control {
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  padding: 17px 20px;
  font-family: "Roboto", sans-serif;
}

.contact-page-form .form-group .nice-select:focus,
.contact-page-form .form-group .form-control:focus {
  -webkit-box-shadow: 0 0 0 1px var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color);
}

.contact-page-form .form-group .nice-select .current {
  font-weight: 400;
  color: var(--base-color);
}

.contact-page-form .form-group .nice-select:after {
  right: 20px;
  margin-top: -6px;
  border-color: var(--base-color);
}

.our-location iframe {
  height: 750px;
}

@media only screen and (max-width: 1399px) {
  .our-location iframe {
    height: 550px;
  }
}

@media only screen and (max-width: 991px) {
  .our-location iframe {
    height: 450px;
  }
}

@media only screen and (max-width: 575px) {
  .our-location iframe {
    height: 350px;
  }
}

.has-error .help-block.with-errors {
  margin-top: 5px;
  color: red;
}

#msgSubmit.h4 {
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 10px;
}

/*******************************************************/
/******************* ## Features Area ******************/
/*******************************************************/
.feature-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.feature-item:not(:last-child) {
  margin-bottom: 14px;
}

.feature-item .icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  font-size: 24px;
  margin-top: -5px;
  margin-right: 20px;
  color: var(--primary-color);
}

.feature-item .content h5 {
  margin-top: -10px;
  margin-bottom: 3px;
}

@media only screen and (max-width: 375px) {
  .feature-item .content h5 {
    font-size: 22px;
  }
}

.feature-item.style-two {
  margin-bottom: 45px;
}

.feature-item.style-two .icon {
  margin-top: 0;
  font-size: 50px;
  margin-right: 35px;
}

@media only screen and (max-width: 375px) {
  .feature-item.style-two .icon {
    font-size: 35px;
    margin-right: 20px;
  }
}

.feature-item.style-two .content h3 {
  margin-top: -15px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 375px) {
  .feature-item.style-two .content h3 {
    font-size: 30px;
  }
}

.feature-item.style-two .content h4 {
  margin-top: -10px;
  margin-bottom: 10px;
}

.feature-item.style-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 30px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}

.feature-item.style-three:not(:last-child) {
  margin-bottom: 40px;
}

.feature-item.style-three .content h3 {
  margin-bottom: 0;
}

.feature-item.style-three .content p {
  margin-bottom: 0;
}

.feature-item.style-three .content p span {
  color: var(--primary-color);
}

.feature-item.style-three .details-btn {
  color: var(--heading-color);
  border: 1px solid var(--border-color);
}

.feature-item.style-three:hover {
  border-color: var(--heading-color);
}

.feature-item.style-three:hover .content h3 a {
  color: var(--primary-color);
  text-decoration: underline;
}

.feature-item.style-three:hover .details-btn {
  color: white;
  background: var(--primary-color);
  border-color: var(--primary-color);
}

@media only screen and (min-width: 992px) {
  .superior-room-content {
    max-width: 440px;
  }
}

.text-white .feature-item.style-two .icon i {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.text-white .feature-item.style-two .content p {
  opacity: 0.65;
}

.text-white .feature-item.style-two:hover .icon i {
  color: var(--primary-color);
}

.text-white .feature-item.style-two:hover .content h4 a {
  text-decoration: underline;
}

.popular-clients {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: -30px;
  bottom: -30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 220px;
  padding: 15px 30px 22px;
  background: var(--primary-color);
}

.popular-clients span {
  width: 100%;
  color: white;
  font-size: 20px;
  margin-bottom: 15px;
  font-family: "Cormorant Garamond", serif;
}

.popular-clients img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.popular-clients img:not(:last-child) {
  margin-right: -7px;
}

.feature-item-three {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  text-align: center;
  margin-bottom: 30px;
  padding: 28px 15px 18px;
}

.feature-item-three .icon {
  line-height: 1;
  font-size: 50px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.feature-item-three h6 {
  font-size: 20px;
}

.feature-item-three:hover {
  background: var(--primary-color);
}

.feature-item-three:hover h6 a,
.feature-item-three:hover .icon {
  color: white;
}

/*******************************************************/
/****************** ## Counter Section *****************/
/*******************************************************/
.for-bg-and-shapes .bg-color-and-shapes {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 172%;
}

@media only screen and (min-width: 1200px) {

  .for-bg-and-shapes .bg-color-and-shapes .wave-shapes:after,
  .for-bg-and-shapes .bg-color-and-shapes .wave-shapes-two:after {
    width: 456px;
    height: 400px;
  }

  .for-bg-and-shapes .bg-color-and-shapes .wave-shapes:before,
  .for-bg-and-shapes .bg-color-and-shapes .wave-shapes-two:before {
    width: 566px;
    height: 500px;
  }
}

.triangle-shapes,
.triangle-shapes:before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  z-index: -1;
  background: rgba(171, 96, 52, 0.05);
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
  clip-path: polygon(0 0, 0 100%, 100% 100%);
}

.triangle-shapes {
  width: 400px;
  height: 400px;
}

.triangle-shapes:before {
  width: 70%;
  height: 70%;
}

.triangle-shapes-two,
.triangle-shapes-two:after,
.triangle-shapes-two:before {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  z-index: -1;
  background: rgba(171, 96, 52, 0.05);
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0);
  clip-path: polygon(0 100%, 100% 100%, 100% 0);
}

.triangle-shapes-two {
  width: 700px;
  height: 700px;
}

@media only screen and (max-width: 575px) {
  .triangle-shapes-two {
    width: 400px;
    height: 400px;
  }
}

.triangle-shapes-two:before {
  width: 80%;
  height: 80%;
}

.triangle-shapes-two:after {
  width: 60%;
  height: 60%;
}

.counter-item {
  background: white;
  text-align: center;
  margin-bottom: 30px;
}

.counter-item .count-text {
  color: white;
  display: block;
  line-height: 1;
  font-size: 65px;
  padding: 10px 15px 20px;
  font-family: "Cormorant Garamond", serif;
  background: var(--primary-color);
}

.counter-item .count-text:after {
  content: "+";
}

.counter-item .counter-title {
  padding: 14px;
  display: block;
  line-height: 1;
  font-size: 18px;
  color: var(--heading-color);
}

.counter-item.style-two {
  text-align: left;
  background: transparent;
}

.counter-item.style-two .icon {
  width: 80px;
  height: 80px;
  color: white;
  font-size: 33px;
  line-height: 80px;
  margin-left: 40px;
  text-align: center;
  background: var(--primary-color);
}

.counter-item.style-two .count-text:after {
  color: var(--primary-color);
}

.counter-item.style-two .count-text,
.counter-item.style-two .counter-title {
  padding-left: 40px;
  padding-right: 40px;
  background: var(--black-color);
}

.counter-item.style-two .counter-title {
  padding-top: 5px;
  padding-bottom: 35px;
  color: rgba(255, 255, 255, 0.65);
}

@media only screen and (min-width: 376px) {
  .counter-item.style-two .counter-title {
    font-size: 22px;
  }
}

.counter-item.style-three {
  background: transparent;
}

.counter-item.style-three .count-text {
  color: var(--heading-color);
  background: transparent;
}

.counter-item.style-three .count-text:after {
  color: var(--primary-color);
}

.counter-item.style-three .counter-title {
  color: var(--base-color);
  padding-top: 25px;
  margin-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  border-top: 1px solid var(--border-color);
}

/* Counter Style Two */
@media only screen and (min-width: 1200px) {
  .about-three-right {
    margin-left: -50px;
  }
}

@media only screen and (min-width: 376px) {
  .counter-item-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.counter-item-two:not(:last-child) {
  margin-bottom: 60px;
}

.counter-item-two .count-text {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 170px;
  line-height: 1;
  font-size: 65px;
  font-weight: 700;
  margin-bottom: 25px;
  display: inline-block;
  color: var(--primary-color);
  font-family: "Cormorant Garamond", serif;
}

@media only screen and (max-width: 575px) {
  .counter-item-two .count-text {
    width: 120px;
    font-size: 55px;
  }
}

.counter-item-two .count-text:after {
  content: "+";
}

.counter-item-two .content h4 {
  margin-bottom: 15px;
}

@media only screen and (min-width: 576px) {
  .counter-item-two .content h4 {
    font-size: 30px;
  }
}

.counter-item-two .content p {
  margin-bottom: 0;
}

/*******************************************************/
/******************* ## Rooms Section ******************/
/*******************************************************/
.room-item {
  background: white;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.03);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.03);
}

.room-item .image {
  position: relative;
}

.room-item .image img {
  width: 100%;
}

.room-item .image .category {
  position: absolute;
  left: 0;
  bottom: 0;
  color: white;
  font-weight: 500;
  padding: 4px 25px;
  text-transform: uppercase;
  background: var(--primary-color);
}

.room-item .content {
  padding: 40px 50px;
}

@media only screen and (max-width: 991px) {
  .room-item .content {
    padding-left: 33px;
    padding-right: 33px;
  }
}

.room-item .content h4 {
  margin-bottom: 12px;
}

.room-item .content .blog-meta li svg {
  color: var(--primary-color);
}

.room-item .content .price span {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-color);
}

.room-item .theme-btn.style-two {
  width: 100%;
  border: none;
  background: var(--lighter-color);
}

.room-item:hover .theme-btn.style-two {
  color: white;
  background: var(--primary-color);
}

.room-item.style-two {
  margin-bottom: 50px;
  -webkit-box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
}

.room-item.style-two .theme-btn {
  width: 100%;
}

.room-item.style-two:not(:hover) .theme-btn {
  background: var(--black-color);
}

.room-item.style-three {
  margin-bottom: 70px;
  -webkit-box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
}

.room-item.style-three .image .category {
  left: auto;
  right: 10px;
  bottom: 10px;
}

.room-item.style-three .content {
  padding-top: 30px;
}

@media only screen and (max-width: 375px) {
  .room-item.style-three .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.room-item.style-three .content .price {
  font-weight: 500;
  margin-bottom: 3px;
  letter-spacing: 2.4px;
  color: var(--primary-color);
  text-transform: uppercase;
}

.room-item.style-three .content h3 {
  line-height: 1.1;
  margin-bottom: 22px;
}

@media only screen and (max-width: 991px) {
  .room-item.style-three .content h3 {
    font-size: 30px;
  }
}

.room-item.style-three .content .blog-meta {
  margin-bottom: 35px;
}

/* Room Itme Two */
.room-two-active {
  margin-left: -15px;
  margin-right: -15px;
}

.room-two-active .room-two-item {
  margin-left: 15px;
  margin-right: 15px;
}

.room-two-active .slick-dots {
  margin-top: 40px;
}

.room-two-active .slick-dots li:not(.slick-active) {
  opacity: 1;
  background: white;
}

.room-two-item {
  margin-bottom: 30px;
}

.room-two-item .image img {
  width: 100%;
}

.room-two-item .content {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  position: relative;
  padding: 30px 50px 20px;
}

@media only screen and (max-width: 1599px) {
  .room-two-item .content {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media only screen and (max-width: 1599px) {
  .room-two-item .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 1599px) {
  .room-two-item .content h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .room-two-item .content h3 {
    font-size: 25px;
  }
}

.room-two-item .content .blog-meta li i {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: var(--primary-color);
}

.room-two-item .content .price {
  width: 100px;
  height: 100px;
  color: white;
  padding: 25px 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  line-height: 1.2;
  position: absolute;
  right: 40px;
  top: -50px;
  border-radius: 50%;
  text-align: center;
  background: var(--primary-color);
}

@media only screen and (max-width: 1599px) {
  .room-two-item .content .price {
    top: -70px;
  }
}

.room-two-item .content .price b {
  font-size: 24px;
}

.room-two-item:hover .content {
  background: var(--primary-color);
}

.room-two-item:hover .content h3 a,
.room-two-item:hover .content .blog-meta li i,
.room-two-item:hover .content .blog-meta li a {
  color: white;
}

.room-two-item:hover .content .price {
  background: white;
  color: var(--heading-color);
}

@media only screen and (min-width: 768px) {
  .superior-room-image {
    padding-left: 50px;
  }
}

.superior-room-image .experience-years {
  padding: 40px 50px;
  background: var(--primary-color);
}

@media only screen and (min-width: 768px) {
  .superior-room-image .experience-years {
    position: absolute;
    left: 0;
    bottom: 50px;
    max-width: 430px;
  }
}

@media only screen and (max-width: 479px) {
  .superior-room-image .experience-years {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .superior-room-image .experience-years h3 {
    font-size: 30px;
  }
}

/* Room Style Three */
.room-three-item {
  margin-bottom: 50px;
}

.room-three-item .image {
  position: relative;
  margin-bottom: 30px;
}

.room-three-item .image img {
  width: 100%;
}

.room-three-item .image .image-over {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.room-three-item .image .image-over .category {
  color: white;
  font-size: 14px;
  padding: 2px 25px;
  text-transform: uppercase;
  background: var(--primary-color);
}

.room-three-item .image .image-over .price {
  color: white;
  margin-right: 15px;
}

.room-three-item .image .image-over .price span {
  font-size: 24px;
  font-weight: 500;
}

.room-three-item .image:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(29, 27, 26, 0)),
      to(#1d1b1a));
  background: -webkit-linear-gradient(top,
      rgba(29, 27, 26, 0) 0%,
      #1d1b1a 100%);
  background: -o-linear-gradient(top, rgba(29, 27, 26, 0) 0%, #1d1b1a 100%);
  background: linear-gradient(180deg, rgba(29, 27, 26, 0) 0%, #1d1b1a 100%);
}

.room-three-item .content h4 {
  margin-bottom: 15px;
}

.room-three-item .content h4 a {
  color: white;
}

.room-three-item .content .blog-meta li i {
  color: var(--primary-color);
}

/* Room Tab */
.room-tab {
  padding: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: var(--black-color);
}

.room-tab li button {
  font-size: 24px;
  font-weight: 600;
  padding: 5px 20px;
  color: var(--base-color);
  font-family: "Cormorant Garamond", serif;
  background-color: transparent;
}

.room-tab li button:hover {
  color: var(--base-color);
}

.room-tab li button.active {
  color: white;
  background: var(--primary-color);
}

/* Room List Style */
.room-list-item {
  margin-bottom: 50px;
}

@media only screen and (min-width: 992px) {
  .room-list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media only screen and (min-width: 992px) {
  .room-list-item .image {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    max-width: 50%;
  }
}

.room-list-item .image img {
  width: 100%;
}

.room-list-item .content {
  background: white;
  padding: 40px 50px;
  position: relative;
  -webkit-box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
}

@media only screen and (min-width: 992px) {
  .room-list-item .content {
    max-width: 565px;
    margin-left: -25px;
  }
}

@media only screen and (max-width: 479px) {
  .room-list-item .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.room-list-item .content h3 {
  margin-bottom: 15px;
}

@media only screen and (max-width: 479px) {
  .room-list-item .content h3 {
    font-size: 33px;
  }
}

@media only screen and (max-width: 375px) {
  .room-list-item .content h3 {
    font-size: 28px;
  }
}

.room-list-item .content .blog-meta {
  margin-bottom: 20px;
}

.room-list-item .content .blog-meta li i {
  color: var(--primary-color);
}

.room-list-item .content hr {
  margin-top: 35px;
  margin-bottom: 40px;
}

.room-list-item .price-more {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.room-list-item .price-more .price span {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-color);
}

.room-list-item .price-more .read-more {
  text-decoration: underline;
}

/* Room Details */
.room-details-content .blog-meta li>i {
  color: var(--primary-color);
}

.room-details-content .price {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
  color: var(--primary-color);
  text-transform: uppercase;
}

.room-location iframe {
  height: 540px;
}

@media only screen and (min-width: 1400px) {
  .room-details-sidebar {
    margin-left: 80px;
  }
}

.room-details-images .slick-dots {
  margin-top: 25px;
}

.room-details-images .slick-dots li {
  width: 7px;
  height: 7px;
  margin: 5px;
}

.room-details-images .slick-dots li:before {
  content: none;
}

.room-calendar.fc {
  padding: 50px;
  background: white;
  -webkit-box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
}

.room-calendar.fc .fc-toolbar-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--heading-color);
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.room-calendar.fc .fc-col-header-cell-cushion {
  color: var(--heading-color);
  font-weight: 500;
  padding: 10px;
}

.room-calendar.fc .fc-button {
  border-radius: 0;
  color: var(--heading-color);
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid var(--border-color);
}

.room-calendar.fc .fc-button:focus,
.room-calendar.fc .fc-button:disabled {
  color: white;
  outline: none;
  background: var(--primary-color);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: var(--primary-color);
}

.room-calendar.fc .fc-day-today {
  color: white;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.room-calendar.fc .fc-day-today a {
  color: white;
}

.room-calendar.fc .fc-highlight {
  background: rgba(171, 96, 52, 0.1);
}

/*******************************************************/
/******************* ## Hotel Section ******************/
/*******************************************************/
.hotel-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  background: var(--lighter-color);
}

.hotel-item .content {
  padding: 60px 45px 70px;
}

@media only screen and (min-width: 480px) {
  .hotel-item .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hotel-item .content {
    padding: 35px 30px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .hotel-item .content {
    padding: 35px 30px 45px;
  }
}

.hotel-item .content h3 {
  margin-bottom: 10px;
}

.hotel-item .content .price {
  margin-bottom: 30px;
}

.hotel-item .content .price span {
  font-size: 27px;
  font-weight: 600;
  color: var(--primary-color);
}

.hotel-item .content .theme-btn {
  padding: 8px 40px;
}

@media only screen and (min-width: 480px) {
  .hotel-item .image {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    max-width: 48%;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
}

.hotel-item .image img {
  width: 100%;
}

.hotel-item:hover .content h3 a {
  color: var(--primary-color);
  text-decoration: underline;
}

.hotel-item:hover .content .theme-btn {
  color: white;
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.hotel-carousel-active {
  margin-left: -15px;
  margin-right: -15px;
}

.hotel-carousel-active .hotel-item {
  margin-left: 15px;
  margin-right: 15px;
}

@media only screen and (min-width: 480px) {
  .hotel-carousel-active .hotel-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.hotel-carousel-active .slick-dots {
  margin-top: 40px;
}

/*******************************************************/
/******************** ## Blog Area ********************/
/*******************************************************/
.blog-item {
  margin-bottom: 55px;
}

@media only screen and (min-width: 576px) {
  .blog-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.blog-item .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 35%;
  position: relative;
  margin-right: 40px;
}

@media only screen and (max-width: 575px) {
  .blog-item .image {
    max-width: none;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .blog-item .image img {
    width: 100%;
  }
}

.blog-item .content {
  font-size: 18px;
  line-height: 1.4;
}

.blog-item .content .date {
  display: block;
  margin-bottom: 10px;
}

.blog-item .content .date i {
  margin-right: 8px;
}

.blog-item .content h4 {
  line-height: 1.4;
  margin-bottom: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-item .content h4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .blog-item .content h4 {
    font-size: 20px;
  }
}

.blog-item .content .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-item .content .author img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.blog-item .content .author span {
  display: block;
}

.blog-item .content .author a {
  font-weight: 500;
}

.blog-item .content .author a:not(:hover) {
  color: var(--heading-color);
}

/* Blog Grid */
.blog-grid-item {
  margin-bottom: 30px;
  border-bottom: 3px solid transparent;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.blog-grid-item .image {
  position: relative;
}

.blog-grid-item .image img {
  width: 100%;
  height: 300px !important;
}

.blog-grid-item .image .blog-meta {
  bottom: 0;
  left: 40px;
  right: 40px;
  color: white;
  margin-bottom: 0;
  position: absolute;
  padding: 16px 12px 5px;
  background: var(--primary-color);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .blog-grid-item .image .blog-meta {
    left: 25px;
    right: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-grid-item .image .blog-meta {
    font-size: 14px;
  }
}

.blog-grid-item .image .blog-meta li {
  margin-left: 12px;
  margin-right: 12px;
}

.blog-grid-item .image .blog-meta li a {
  color: white;
}

.blog-grid-item .theme-btn {
  margin-bottom: 10px;
}

.blog-grid-item:hover {
  border-color: var(--primary-color);
}

.blog-grid-item:hover h4 a {
  color: var(--heading-color);
  text-decoration: underline;
}

.blog-grid-item:not(:hover) .theme-btn {
  color: var(--base-color);
  background: var(--lighter-color);
}

.blog-grid-item.style-two {
  padding: 30px;
  background: white;
  border-bottom: none;
}

@media only screen and (max-width: 375px) {
  .blog-grid-item.style-two {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.blog-grid-item.style-two .category {
  color: var(--primary-color);
}

.blog-grid-item.style-two h4 {
  margin-bottom: 20px;
}

.blog-grid-item.style-two .blog-meta {
  padding-bottom: 15px;
}

.blog-grid-item.style-two .image {
  margin-bottom: 20px;
}

.blog-content {
  background: white;
  padding: 35px 40px 30px;
  -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 991px) {
  .blog-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.blog-content h4 {
  margin-bottom: 10px;
}

@media only screen and (max-width: 991px) {
  .blog-content h4 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 375px) {
  .blog-content h4 {
    font-size: 20px;
  }
}

.blog-content p {
  width: 100%;
  height: 150px;
  overflow: hidden;
  margin-bottom: 20px;
}

.blog-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-meta li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.3;
  margin-bottom: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-meta li i {
  margin-top: -3px;
  margin-right: 7px;
}

.blog-meta li:not(:last-child) {
  margin-right: 35px;
}

@media only screen and (max-width: 991px) {
  .blog-meta li:not(:last-child) {
    margin-right: 25px;
  }
}

.blog-meta li .ratting i {
  margin-top: 0;
}

.blog-meta.style-two {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blog-meta.style-two li {
  font-size: 22px;
  margin-left: 18px;
  margin-right: 18px;
}

@media only screen and (max-width: 767px) {
  .blog-meta.style-two li {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-meta.style-two li {
    font-size: 16px;
    margin-left: 12px;
    margin-right: 12px;
  }
}

/* Blog List */
.blog-list-item {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) {
  .blog-list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.blog-list-item .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 44%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

@media only screen and (max-width: 1199px) {
  .blog-list-item .image {
    max-width: 100%;
  }
}

.blog-list-item .image img {
  width: 100%;
}

.blog-list-item .blog-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 60px 30px;
}

@media only screen and (max-width: 767px) {
  .blog-list-item .blog-content {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-list-item .blog-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.blog-list-item .blog-content h4 {
  line-height: 1.52;
  margin-bottom: 15px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 576px) {
  .blog-list-item .blog-content h4 {
    font-size: 27px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-list-item .blog-content h4 {
    font-size: 20px;
    line-height: 1.65;
  }
}

@media only screen and (max-width: 375px) {
  .blog-list-item .blog-content h4 {
    font-size: 16px;
  }
}

/* Blog Four */
.blog-four-left-image {
  height: 100%;
  min-height: 500px;
  background-size: cover;
  background-position: center;
}

.blog-four-right-content {
  padding: 50px 70px;
  background: #f4edcc;
  border: 3px solid black;
}

@media only screen and (max-width: 575px) {
  .blog-four-right-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.blog-four-item .date {
  display: block;
  margin-bottom: 5px;
}

.blog-four-item .date i {
  margin-right: 10px;
}

.blog-four-item h4 {
  line-height: 1.55;
  margin-bottom: 16px;
}

@media only screen and (min-width: 480px) {
  .blog-four-item h4 {
    font-size: 27px;
  }
}

@media only screen and (max-width: 375px) {
  .blog-four-item h4 {
    font-size: 22px;
  }
}

.blog-four-item .theme-btn {
  padding-top: 9px;
  padding-bottom: 9px;
}

.blog-four-item .theme-btn:hover {
  background: var(--primary-color);
}

.blog-four-item:not(:last-child) {
  margin-bottom: 35px;
  padding-bottom: 46px;
  border-bottom: 2px solid black;
}

/* Blog Details */
.blog-details-content .blog-meta {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(41, 48, 67, 0.1);
}

.big-first-letter:first-letter {
  float: left;
  margin-top: 8px;
  font-size: 30px;
  font-weight: 700;
  margin-right: 20px;
  color: white;
  padding: 5px 18px 8px;
  font-family: "Cormorant Garamond", serif;
  background: var(--primary-color);
}

blockquote {
  margin-top: 45px;
  margin-bottom: 45px;
  font-size: 33px;
  line-height: 1.2;
  font-weight: 700;
  position: relative;
  padding-left: 150px;
  color: var(--heading-color);
  font-family: "Cormorant Garamond", serif;
  border-left: 5px solid var(--primary-color);
}

blockquote:before {
  content: "\f10d";
  line-height: 1;
  position: absolute;
  left: 50px;
  top: 3px;
  font-size: 60px;
  font-weight: 300;
  font-family: "Font Awesome 5 Pro";
}

@media only screen and (max-width: 767px) {
  blockquote {
    font-size: 28px;
    padding-left: 100px;
  }

  blockquote:before {
    left: 30px;
    font-size: 40px;
  }
}

@media only screen and (max-width: 479px) {
  blockquote {
    font-size: 22px;
    padding-left: 50px;
  }

  blockquote:before {
    left: 10px;
    font-size: 25px;
  }
}

blockquote .blockquote-footer {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: var(--base-color);
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
  margin-top: 20px;
}

blockquote .blockquote-footer:before {
  content: "———";
  letter-spacing: -2px;
  margin-right: 30px;
}

@media only screen and (max-width: 479px) {
  blockquote .blockquote-footer {
    font-size: 16px;
  }
}

.tag-share {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.tag-share .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}

.tag-share .item h5 {
  margin: 0 20px 0 0;
}

.tag-share .item .tag-coulds a {
  border: 1px solid rgba(29, 27, 26, 0.1);
}

.tag-share .item .tag-coulds a:not(:hover) {
  color: var(--base-color);
  background: white;
}

/* Next Prev Blog */
.next-prev-blog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.next-prev-blog .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 315px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.next-prev-blog .item .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 100px;
  margin-right: 30px;
}

@media only screen and (max-width: 375px) {
  .next-prev-blog .item .image {
    max-width: 90px;
  }
}

.next-prev-blog .item h6 {
  font-weight: 700;
  line-height: 1.65;
  margin-bottom: 5px;
}

@media only screen and (min-width: 376px) {
  .next-prev-blog .item h6 {
    font-size: 22px;
  }
}

/* Comments */
.comments {
  padding: 40px 50px 35px;
  background: white;
  -webkit-box-shadow: 0px 4px 60px 0px rgba(12, 11, 11, 0.07);
  box-shadow: 0px 4px 60px 0px rgba(12, 11, 11, 0.07);
}

@media only screen and (max-width: 767px) {
  .comments {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 375px) {
  .comments {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.comment-title {
  font-weight: 700;
  margin-bottom: 0;
}

.comment-body {
  padding-top: 40px;
  padding-bottom: 5px;
}

@media only screen and (min-width: 768px) {
  .comment-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.comment-body .author-thumb {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 100px;
  margin-right: 40px;
  margin-bottom: 20px;
}

.comment-body .author-thumb img {
  border-radius: 50%;
}

.comment-body .content .blog-meta {
  margin-bottom: 5px;
}

.comment-body .content .blog-meta li {
  text-transform: capitalize;
}

.comment-body .content .blog-meta li:not(:last-child) {
  margin-right: 15px;
}

.comment-body .content h6 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 20px;
}

@media only screen and (min-width: 376px) {
  .comment-body .content h6 {
    font-size: 22px;
  }
}

.comment-body .content p {
  margin-bottom: 10px;
}

@media only screen and (min-width: 376px) {
  .comment-body .content p {
    font-size: 18px;
  }
}

.comment-body.comment-child {
  margin-left: 50px;
}

@media only screen and (max-width: 575px) {
  .comment-body.comment-child {
    margin-left: 30px;
  }
}

.admin-comment .comment-body {
  border: none;
  padding: 40px 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .admin-comment .comment-body {
    display: block;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 375px) {
  .admin-comment .comment-body {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.admin-comment .comment-body .author-thumb {
  max-width: 160px;
  margin-right: 50px;
}

@media only screen and (min-width: 768px) {
  .admin-comment .comment-body .author-thumb {
    margin-bottom: 0;
  }
}

.admin-comment .comment-body .social-style-two {
  margin-left: -17px;
  margin-right: -17px;
}

.admin-comment .comment-body .social-style-two a {
  margin-left: 17px;
  margin-right: 17px;
  color: var(--base-color);
}

.admin-comment .comment-body .social-style-two a:hover {
  color: var(--primary-color);
}

/* Comment Form */
.comment-form {
  padding: 40px 60px 50px;
}

@media only screen and (max-width: 479px) {
  .comment-form {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.comment-form h3 {
  font-weight: 700;
  margin-bottom: 5px;
}

.comment-form .form-group {
  margin-bottom: 30px;
}

.comment-form .form-control {
  border: none;
  border-radius: 0;
  background: white;
  padding: 15px 30px;
  color: var(--base-color);
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.comment-form .form-control:focus {
  outline: 1px solid var(--border-color);
}

.comment-form .form-control::-webkit-input-placeholder {
  color: var(--base-color);
}

.comment-form .form-control:-ms-input-placeholder {
  color: var(--base-color);
}

.comment-form .form-control::-ms-input-placeholder {
  color: var(--base-color);
}

.comment-form .form-control::placeholder {
  color: var(--base-color);
}

.blog-page-area .blog-grid-item {
  margin-bottom: 50px;
}

/*******************************************************/
/******************* ## Video Section ******************/
/*******************************************************/
.video-play {
  color: white;
  font-size: 18px;
  display: inline-block;
  width: 90px;
  height: 90px;
  background: var(--primary-color);
  line-height: 90px;
  border-radius: 50%;
  text-align: center;
}

@media only screen and (max-width: 575px) {
  .video-play {
    width: 70px;
    height: 70px;
    font-size: 16px;
    line-height: 70px;
  }
}

.video-play.style-two {
  width: 60px;
  height: 60px;
  font-size: 12px;
  line-height: 60px;
  background: white;
  color: var(--primary-color);
}

.video-play.style-two>i {
  color: var(--primary-color);
}

/* Video Play With Text */
.video-play-text {
  color: white;
  height: 100%;
  font-size: 20px;
  line-height: 1.35;
  padding: 25px 30px;
  background: var(--primary-color);
  font-family: "Cormorant Garamond", serif;
}

@media only screen and (max-width: 375px) {
  .video-play-text {
    padding: 15px 20px;
  }
}

.video-play-text span {
  display: block;
  margin-top: 15px;
}

.video-play-text:hover {
  color: white;
}

.video-part {
  z-index: 1;
  position: relative;
}

.video-part::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.45;
  background-color: #1d1b1a;
}

.video-part:before {
  z-index: 1;
}

.video-part img {
  width: 100%;
}

.video-part .video-play {
  z-index: 2;
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: var(--primary-color);
  font-size: 14px;
  line-height: 100px;
  background: white;
}

@media only screen and (max-width: 767px) {
  .video-part .video-play {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}

@media only screen and (max-width: 375px) {
  .video-part .video-play {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

/*******************************************************/
/******************* ## Activity Area ******************/
/*******************************************************/
.activity-item {
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
}

.activity-item:before {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0)),
      to(#000));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
}

.activity-item .image img {
  width: 100%;
}

.activity-item .content {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  color: white;
  padding: 40px;
  max-width: 250px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.activity-item .content .icon {
  font-size: 45px;
  margin-bottom: 30px;
}

.activity-item .content h5 {
  margin-bottom: -5px;
}

.activity-item .content a {
  color: white;
}

.activity-item .content .theme-btn {
  opacity: 0;
  margin-top: 30px;
  position: absolute;
}

.activity-item:hover .content {
  bottom: 84px;
}

.activity-item:hover .content .theme-btn {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/*******************************************************/
/****************** ## Services Area *******************/
/*******************************************************/
.service-item {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  position: relative;
  padding: 35px 30px;
}

@media only screen and (max-width: 375px) {
  .service-item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.service-item .icon {
  font-size: 40px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin: 0 auto 30px;
  color: var(--primary-color);
  width: 104px;
  height: 104px;
  background: #f7f0eb;
  line-height: 80px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-item:hover {
  z-index: 2;
  background: white;
  -webkit-box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
}

/* .service-item:hover .icon {
  color: white;
  background: var(--primary-color);
} */

.service-item.style-two {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: left;
  margin-bottom: 30px;
}

.service-item.style-two .icon {
  padding: 0;
  width: auto;
  height: auto;
  line-height: 1;
  font-size: 50px;
  text-align: left;
  color: var(--primary-color);
  background: transparent;
}

/* Service Style Two */
.services-three-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.services-three-slider .service-item-two {
  margin: 15px;
}

.services-three-slider .slick-dots {
  margin-top: 50px;
}

.service-item-two {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 35px 40px;
  margin-bottom: 30px;
  background: var(--lighter-color);
}

.service-item-two h4 {
  margin-top: 20px;
  margin-bottom: 26px;
}

.service-item-two .icon {
  line-height: 1;
  font-size: 50px;
  margin-top: 22px;
  color: var(--primary-color);
}

.service-item-two:hover {
  background: white;
  -webkit-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
}

.service-item-two:hover h4 a {
  text-decoration: underline;
}

/* Service Four */
.services-area-four .triangle-shapes-two {
  width: 400px;
  height: 400px;
}

/*******************************************************/
/***************** ## Sidebar Widgets *****************/
/*******************************************************/
.main-sidebar {
  background: white;
  padding: 50px 40px;
  -webkit-box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
}

.widget:not(:last-child) {
  margin-bottom: 35px;
}

.widget-title {
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (min-width: 376px) {
  .widget-title {
    font-size: 30px;
  }
}

.widget-search .widget-title {
  margin-bottom: 20px;
}

.widget-search form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 13px 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--lighter-color);
}

.widget-search form input {
  padding: 0;
  border: none;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  font-family: "Roboto", sans-serif;
}

.widget-search form button {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background: transparent;
}

.widget-category ul li {
  font-size: 18px;
}

.widget-category ul li:not(:last-child) {
  margin-bottom: 13px;
}

.widget-category ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.widget-category ul li a:not(:hover) {
  color: var(--heading-color);
}

.widget-recent-news ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget-recent-news ul li:not(:last-child) {
  margin-bottom: 20px;
}

.widget-recent-news ul li .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 100px;
  margin-right: 30px;
}

@media only screen and (max-width: 375px) {
  .widget-recent-news ul li .image {
    max-width: 80px;
    margin-right: 15px;
  }
}

.widget-recent-news ul li h5 {
  font-size: 22px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 375px) {
  .widget-recent-news ul li h5 {
    font-size: 16px;
  }
}

.tag-coulds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -10px -5px 0;
}

.tag-coulds a {
  font-size: 14px;
  font-weight: 500;
  padding: 2px 20px;
  margin: 10px 5px 0;
  color: var(--primary-color);
  text-transform: capitalize;
  background: rgba(171, 96, 52, 0.2);
}

.tag-coulds a:hover {
  color: white;
  background: var(--primary-color);
}

.gallery-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -10px -5px 0;
}

.gallery-widget a {
  max-width: 105px;
  margin: 10px 5px 0;
  z-index: 1;
  position: relative;
}

.gallery-widget a::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.7;
  background-color: var(--heading-color);
}

.gallery-widget a:before {
  z-index: 1;
  opacity: 0;
  color: white;
  font-size: 22px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  content: "\f16d";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Font Awesome 5 Brands";
}

.gallery-widget a:hover:before {
  opacity: 1;
}

/* Room Sidebar */
.widget-search-filter .form-group {
  margin-bottom: 30px;
}

.widget-search-filter .form-group:after {
  display: block;
  clear: both;
  content: "";
}

.widget-search-filter .form-group label {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--heading-color);
  font-family: "Cormorant Garamond", serif;
}

.widget-search-filter .form-group input,
.widget-search-filter .form-group .nice-select {
  color: #cdcdcd;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding: 16px 25px;
  border-bottom: none;
  font-family: "Roboto", sans-serif;
}

.widget-search-filter .form-group .nice-select .current {
  color: #cdcdcd;
}

.widget-search-filter .form-group .nice-select:after {
  right: 25px;
  border-color: #cdcdcd;
}

/*******************************************************/
/***************** ## Testimonials Area ****************/
/*******************************************************/
.booking-search {
  padding: 60px 50px;
}

.booking-search:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  background: var(--primary-color);
  -webkit-transform: rotate(-1.91deg);
  -ms-transform: rotate(-1.91deg);
  transform: rotate(-1.91deg);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .booking-search {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .booking-search {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .testimonial-right {
    padding-left: 60px;
  }
}

.testimonial-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.testimonial-part .testimonial-active {
  width: calc(100% - 110px);
}

@media only screen and (max-width: 1199px) {
  .testimonial-part .testimonial-active {
    width: calc(100% - 90px);
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-part .testimonial-active {
    width: calc(100% - 50px);
  }
}

.testimonial-part .testimonial-active .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-part .testimonial-thums {
  width: 60px;
}

@media only screen and (max-width: 479px) {
  .testimonial-part .testimonial-thums {
    width: 40px;
  }
}

.testimonial-part .testi-thumb-item {
  margin-top: 5px;
  margin-bottom: 5px;
}

.testimonial-part .testi-thumb-item img {
  width: 60px;
  height: 60px;
  cursor: pointer;
  -webkit-transition: 0.35;
  -o-transition: 0.35;
  transition: 0.35;
  border-radius: 50%;
}

@media only screen and (max-width: 479px) {
  .testimonial-part .testi-thumb-item img {
    width: 40px;
    height: 40px;
  }
}

.testimonial-part .testi-thumb-item.slick-current img {
  border: 3px solid var(--primary-color);
}

.testimonial-item {
  padding: 45px 50px 50px;
  background: var(--heading-color);
}

@media only screen and (max-width: 767px) {
  .testimonial-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 375px) {
  .testimonial-item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .testimonial-item p {
    font-size: 14px;
    line-height: 1.8;
  }
}

.testimonial-item .testi-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-item .testi-author img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 25px;
}

@media only screen and (max-width: 375px) {
  .testimonial-item .testi-author img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.testimonial-item .testi-author .testi-des h5 {
  margin-bottom: 0;
  line-height: 1.2;
}

@media only screen and (max-width: 375px) {
  .testimonial-item .testi-author .testi-des h5 {
    font-size: 20px;
  }
}

.testimonial-item .testi-author .testi-des span {
  opacity: 0.65;
}

@media only screen and (max-width: 375px) {
  .testimonial-item .testi-author .testi-des span {
    font-size: 14px;
  }
}

.testimonial-dots {
  margin-top: 70px;
}

.testimonial-dots .slick-dots {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

.testimonial-dots .slick-dots li:not(.slick-active) {
  background: white;
}

/* Testimonials Four */
.testimonial-four-content .testimonial-item {
  background: white;
}

.testimonial-four-dots {
  margin-top: 70px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-four-dots {
    margin-top: 40px;
  }
}

.testimonial-four-dots .slick-dots {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

/* Testimonials Two */
.testimonials-area-two {
  z-index: 1;
  position: relative;
}

.testimonials-area-two::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: var(--lighter-color);
}

@media only screen and (min-width: 1600px) {
  .testimonials-area-two:before {
    left: 70px;
    width: calc(100% - 140px);
  }
}

.testimonial-item-two .testi-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-item-two .testi-author img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
  border-radius: 50%;
}

@media only screen and (max-width: 375px) {
  .testimonial-item-two .testi-author img {
    width: 55px;
    height: 55px;
    margin-right: 20px;
  }
}

.testimonial-item-two .testi-author h5 {
  margin-bottom: 0;
}

@media only screen and (max-width: 375px) {
  .testimonial-item-two .testi-author h5 {
    font-size: 20px;
  }
}

.testimonial-item-two .testi-speech {
  font-size: 30px;
  line-height: 1.65;
  margin-bottom: 20px;
  color: var(--heading-color);
}

@media only screen and (max-width: 575px) {
  .testimonial-item-two .testi-speech {
    font-size: 22px;
  }
}

@media only screen and (max-width: 375px) {
  .testimonial-item-two .testi-speech {
    font-size: 20px;
  }
}

.testimonial-item-two .testi-speech:after,
.testimonial-item-two .testi-speech:before {
  line-height: 0;
  font-size: 55px;
}

.testimonial-item-two .testi-speech:before {
  content: "“";
}

.testimonial-item-two .testi-speech:after {
  content: "“";
  display: inline-block;
  -webkit-transform: rotate(180deg) translate(-5px, -10px);
  -ms-transform: rotate(180deg) translate(-5px, -10px);
  transform: rotate(180deg) translate(-5px, -10px);
}

.testimonial-two-right .testimonial-two-dots {
  position: absolute;
  left: 0;
  bottom: 0;
}

/* Testimonials Three */
.testimonials-three-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.testimonials-three-slider .testimonials-item-three {
  margin-left: 15px;
  margin-right: 15px;
}

.testimonials-three-slider .slick-dots {
  margin-top: 40px;
}

.testimonials-item-three {
  padding: 35px 40px;
  background: white;
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 30px;
  border-top: 3px solid var(--primary-color);
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 767px) {
  .testimonials-item-three {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.testimonials-item-three:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: -1;
  content: "";
  border-right: 3px solid var(--primary-color);
}

.testimonials-item-three:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  z-index: -1;
  content: "";
  border-left: 3px solid var(--primary-color);
}

.testimonials-item-three .author {
  margin-bottom: 18px;
}

.testimonials-item-three .author img {
  display: inline-block;
}

.testimonials-item-three .text:before {
  content: "“";
  font-size: 35px;
  color: var(--heading-color);
}

.testimonials-item-three .testi-des {
  margin-top: 25px;
  margin-bottom: -8px;
}

.testimonials-item-three .testi-des h6 {
  margin-bottom: 0;
}

@media only screen and (min-width: 376px) {
  .testimonials-item-three .testi-des h6 {
    font-size: 20px;
  }
}

.testimonials-item-three .testi-des span {
  font-size: 14px;
}

.testimonials-item-three .ratting i {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
}

.testimonials-item-three:hover:after {
  height: 100%;
  width: 100%;
  -webkit-animation: border 1s linear;
  animation: border 1s linear;
  border-bottom: 3px solid var(--primary-color);
}

.testimonials-item-three:hover:before {
  height: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

/*******************************************************/
/****************** ## Search Filter *******************/
/*******************************************************/
.search-filter-inner {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 50px 80px 20px;
  background: #fff;
  -webkit-transform: translateY(-80px);
  -ms-transform: translateY(-80px);
  transform: translateY(-80px);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 1399px) {
  .search-filter-inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.search-filter-inner .section-title {
  width: 100%;
}

.search-filter-inner .section-title .sub-title {
  color: black;
  font-weight: 400;
}

.search-filter-inner .section-title h3 {
  font-size: 35px;
  font-weight: 900;
  color: #a27b40;
}

.search-filter-inner .theme-btn,
.search-filter-inner .filter-item {
  margin-bottom: 30px;
  /*width: calc(23% - 22px); */
}

@media only screen and (max-width: 1199px) {

  .search-filter-inner .theme-btn,
  .search-filter-inner .filter-item {
    width: calc(50% - 22px);
  }
}

@media only screen and (max-width: 575px) {

  .search-filter-inner .theme-btn,
  .search-filter-inner .filter-item {
    width: 100%;
  }
}

.search-filter-inner .filter-item input,
.search-filter-inner .filter-item .nice-select {
  line-height: 1.6;
  padding: 0 0 15px;
  background: transparent;
  border-bottom: 1px solid rgb(0 0 0 / 20%);
}

.search-filter-inner .filter-item input::-webkit-input-placeholder,
.search-filter-inner .filter-item .nice-select::-webkit-input-placeholder {
  color: black;
}

.search-filter-inner .filter-item input:-ms-input-placeholder,
.search-filter-inner .filter-item .nice-select:-ms-input-placeholder {
  color: black;
}

.search-filter-inner .filter-item input::-ms-input-placeholder,
.search-filter-inner .filter-item .nice-select::-ms-input-placeholder {
  color: black;
}

.search-filter-inner .filter-item input::placeholder,
.search-filter-inner .filter-item .nice-select::placeholder {
  color: black;
}

.search-filter-inner .filter-item input:focus,
.search-filter-inner .filter-item .nice-select:focus {
  border-color: black;
}

.search-filter-inner .filter-item input {
  color: black;
}

.search-filter-inner .filter-item input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  /* ../images/icons/calendar.png */
  background: url(/public/newImages/images/icons/calendar.png) no-repeat center;
  width: 16px;
  height: 16px;
}

.search-filter-inner .filter-item .nice-select:after {
  right: 3px;
  margin-top: -10px;
  border-color: black;
}

.search-filter-inner .filter-item .nice-select .current {
  color: black;
}

.search-filter-inner .theme-btn.style-two {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.search-filter-inner .theme-btn.style-two:hover {
  border-color: var(--primary-color);
}

/*******************************************************/
/****************** ## Instagram Area ******************/
/*******************************************************/
.instagram-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.instagram-item {
  position: relative;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .instagram-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .instagram-item {
    width: 33.333%;
  }

  .instagram-item:first-child {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-item {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .instagram-item {
    width: 100%;
  }
}

.instagram-item img {
  width: 100%;
  height: 100%;
}
.animated.fadeIn{
  height: 100%;
}
.instagram-item .content {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--primary-color);
}

@media only screen and (max-width: 575px) {
  .instagram-item .content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.instagram-item .content .icon {
  line-height: 1;
  margin-bottom: 1vw;
}

.instagram-item .content .call-text {
  font-size: 1vw;
}

@media only screen and (max-width: 1199px) {
  .instagram-item .content .call-text {
    font-size: 2vw;
  }
}

.instagram-item .content hr {
  opacity: 1;
  margin-top: 1vw;
  margin-bottom: 1vw;
  width: 50px;
  height: 4px;
}

.instagram-item .content h2,
.instagram-item .content .h2,
.instagram-item .content .icon {
  font-size: 2vw;
}

@media only screen and (max-width: 1199px) {

  .instagram-item .content h2,
  .instagram-item .content .h2,
  .instagram-item .content .icon {
    font-size: 30px;
  }
}

.instagram-item .instagram-gallery .icon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 24px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(171, 96, 52, 0.85);
}

.instagram-item:hover .instagram-gallery .icon {
  opacity: 1;
}

/*******************************************************/
/******************* ## Gallery Area *******************/
/*******************************************************/
@media only screen and (min-width: 1400px) {
  .gallery-active {
    margin-left: 70px;
  }
}

.gallery-active .gallery-item {
  margin-left: 35px;
  margin-right: 35px;
}

@media only screen and (max-width: 1399px) {
  .gallery-active .gallery-item {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.gallery-active .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gallery-item {
  position: relative;
}

.gallery-item .image img {
  width: 100%;
}

.gallery-item .over-content {
  position: absolute;
  left: 20px;
  top: 20px;
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  padding: 25px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background: rgba(171, 96, 52, 0.85);
}

.gallery-item .over-content h2 {
  color: white;
  margin-bottom: 0;
  margin-top: 20px;
}

@media only screen and (max-width: 1399px) {
  .gallery-item .over-content h2 {
    font-size: 35px;
  }
}

.gallery-item:hover .over-content {
  opacity: 1;
}

.gallery-item.style-two {
  margin-bottom: 30px;
}

.gallery-item.style-two .over-content {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 27, 26, 0.85);
}

.gallery-item.style-two .over-content h2 {
  margin-top: 0;
}

@media only screen and (max-width: 1399px) {
  .gallery-item.style-two .over-content h2 {
    font-size: 35px;
  }
}

/* Gallery Page */
.gallery-masonry-active .details-btn {
  color: white;
  margin-bottom: 20px;
  background: var(--primary-color);
}

/*******************************************************/
/****************** ## Pricing Section *****************/
/*******************************************************/
.pricing-area {
  background-repeat: no-repeat;
  background-position: center bottom;
}

.pricing-item {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  margin-bottom: 30px;
  padding: 70px 55px 50px;
  background-color: var(--black-color);
  background-position: top left;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 375px) {
  .pricing-item {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.pricing-item .title {
  color: white;
  margin-bottom: 30px;
}

.pricing-item .price {
  color: white;
  display: block;
  font-size: 65px;
  font-weight: 600;
  margin-bottom: 33px;
  font-family: "Cormorant Garamond", serif;
}

.pricing-item .per-text {
  display: block;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.65);
}

.pricing-item hr {
  margin-top: 40px;
  margin-bottom: 40px;
}

.pricing-item ul {
  padding-bottom: 25px;
}

.pricing-item ul li {
  color: white;
  font-size: 18px;
  text-align: left;
  margin-bottom: 16px;
}

.pricing-item ul li:before {
  content: "\f00c";
  margin-right: 15px;
  color: var(--primary-color);
  font-family: "Font Awesome 5 Pro";
}

.pricing-item ul li.unable {
  color: white;
  opacity: 0.15;
}

.pricing-item ul li.unable:before {
  color: white;
}

.pricing-item:not(:hover) .theme-btn {
  color: rgba(255, 255, 255, 0.65);
  background: rgba(255, 255, 255, 0.1);
}

.pricing-item:hover {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
}

/*******************************************************/
/******************* ## Skillbar Area ******************/
/*******************************************************/
.skillbar:not(:last-child) {
  margin-bottom: 25px;
}

.skillbar-title {
  display: block;
  font-weight: 700;
  margin-bottom: -25px;
  font-family: "Cormorant Garamond", serif;
}

@media only screen and (min-width: 480px) {
  .skillbar-title {
    font-size: 24px;
  }
}

.skillbar-bar {
  width: 0;
  height: 3px;
  margin-top: 35px;
  background: white;
  position: relative;
  overflow: visible !important;
}

.skillbar-bar .skill-bar-percent {
  z-index: 2;
  position: absolute;
  right: 0;
  bottom: 15px;
  font-weight: 700;
  background: var(--primary-color);
  font-family: "Cormorant Garamond", serif;
}

@media only screen and (min-width: 480px) {
  .skillbar-bar .skill-bar-percent {
    font-size: 24px;
  }
}

/*******************************************************/
/******************* ## History Area ******************/
/*******************************************************/
.history-slider-active {
  margin-left: -20px;
  margin-right: -20px;
}

.history-slider-active:after {
  content: "";
  z-index: -1;
  position: absolute;
  left: 50%;
  top: 41.5%;
  width: 111%;
  height: 1px;
  background: var(--border-color);
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

@media only screen and (max-width: 1599px) {
  .history-slider-active:after {
    width: 105%;
  }
}

.history-slider-active .history-slide-item {
  margin-left: 20px;
  margin-right: 20px;
}

.history-slider-active .slick-arrow {
  top: 35.5%;
  z-index: 2;
  background: white;
  position: absolute;
  color: var(--base-color);
}

.history-slider-active .slick-arrow:focus,
.history-slider-active .slick-arrow:hover {
  color: white;
  background: var(--primary-color);
}

.history-slider-active .slick-arrow.prev-arrow {
  left: -200px;
}

@media only screen and (max-width: 1599px) {
  .history-slider-active .slick-arrow.prev-arrow {
    left: -55px;
  }
}

@media only screen and (max-width: 1399px) {
  .history-slider-active .slick-arrow.prev-arrow {
    left: 0;
  }
}

.history-slider-active .slick-arrow.next-arrow {
  right: -200px;
}

@media only screen and (max-width: 1599px) {
  .history-slider-active .slick-arrow.next-arrow {
    right: -55px;
  }
}

@media only screen and (max-width: 1399px) {
  .history-slider-active .slick-arrow.next-arrow {
    right: 0;
  }
}

.history-slide-item .year {
  font-size: 20px;
  font-weight: 700;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 80px;
  height: 80px;
  background: white;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
  color: var(--heading-color);
}

.history-slide-item .content {
  background: white;
  position: relative;
  margin-bottom: 30px;
  padding: 25px 35px 10px;
}

@media only screen and (max-width: 375px) {
  .history-slide-item .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.history-slide-item .content:before {
  content: "";
  position: absolute;
  left: 22px;
  bottom: 100%;
  border-left: 30px solid white;
  border-top: 20px solid transparent;
}

.history-slide-item .content h5 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}

.history-slide-item .content h5 span {
  color: var(--primary-color);
}

.history-slide-item.slick-current .year {
  color: white;
  outline: 8px solid white;
  background: var(--primary-color);
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

/*******************************************************/
/******************** ## FAQs Area *********************/
/*******************************************************/
.faq-image-part img {
  width: 100%;
}

.faq-counter-part {
  position: absolute;
  right: 30px;
  bottom: 15px;
  max-width: calc(100% - 60px);
}

.faq-counter-part .counter-item {
  margin-bottom: 15px;
}

@media only screen and (min-width: 480px) {
  .faq-counter-part .counter-item {
    min-width: 175px;
  }
}

@media only screen and (max-width: 479px) {
  .faq-counter-part .counter-item .count-text {
    font-size: 40px;
  }

  .faq-counter-part .counter-item .counter-title {
    font-size: 14px;
  }
}

.accordion-item {
  border: none;
  background: transparent;
}

.accordion-item .accordion-button {
  font-size: 24px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 15px 0 22px;
  background: transparent;
  border-bottom: 1px solid var(--border-color);
}

@media only screen and (max-width: 375px) {
  .accordion-item .accordion-button {
    font-size: 18px;
  }
}

.accordion-item .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-item .accordion-button:after {
  font-size: 18px;
  background: none;
  content: "\f067";
  font-weight: 400;
  text-align: right;
  font-family: "Font Awesome 5 Pro";
}

.accordion-item .accordion-button:not(.collapsed):after {
  content: "\f068";
  color: var(--primary-color);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.accordion-item .accordion-body {
  padding: 15px 0 0;
}

.accordion-item .accordion-body p:last-child {
  margin-bottom: 0;
}

/*******************************************************/
/******************** ## Shop Area ********************/
/*******************************************************/
.shop-shorter {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.shop-shorter .sort-text {
  font-weight: 500;
  margin-bottom: 15px;
  color: var(--heading-color);
}

.products-dropdown {
  margin-bottom: 15px;
}

.products-dropdown .nice-select {
  font-size: 16px;
  padding: 0 25px 0 0;
  border-bottom: none;
  font-family: "Roboto", sans-serif;
}

.products-dropdown .nice-select .current {
  font-weight: 400;
}

.products-dropdown .nice-select .list {
  margin-top: 15px;
}

.products-dropdown .nice-select:after {
  width: 7px;
  height: 7px;
  right: 5px;
}

.offer-banner-wrap {
  z-index: 1;
  position: relative;
  padding: 110px 100px;
}

@media only screen and (max-width: 767px) {
  .offer-banner-wrap {
    padding: 70px 50px;
  }
}

@media only screen and (max-width: 479px) {
  .offer-banner-wrap {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.offer-banner-wrap:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(12, 11, 11, 0.85)),
      to(rgba(12, 11, 11, 0)));
  background: -webkit-linear-gradient(left,
      rgba(12, 11, 11, 0.85) 0%,
      rgba(12, 11, 11, 0) 100%);
  background: -o-linear-gradient(left,
      rgba(12, 11, 11, 0.85) 0%,
      rgba(12, 11, 11, 0) 100%);
  background: linear-gradient(90deg,
      rgba(12, 11, 11, 0.85) 0%,
      rgba(12, 11, 11, 0) 100%);
}

@media only screen and (min-width: 1200px) {
  .offer-banner-wrap .section-title h2 {
    font-size: 48px;
  }
}

/* Product box */
.product-item {
  margin-bottom: 50px;
}

.product-item .image {
  margin-bottom: 25px;
  position: relative;
}

.product-item .image img {
  width: 100%;
}

.product-item .image .social-style-one {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  padding: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(29, 27, 26, 0.75);
}

.product-item .image .social-style-one a {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

@media only screen and (max-width: 375px) {
  .product-item .image .social-style-one a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.product-item .image .social-style-one a:not(:hover) {
  background: white;
  color: var(--heading-color);
}

.product-item .content .ratting {
  font-size: 13px;
  padding-bottom: 8px;
}

.product-item:hover .social-style-one {
  opacity: 1;
}

/* Product Details */
.preview-images {
  margin-bottom: 30px;
}

.preview-images img {
  width: 100%;
}

.thumb-images {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.thumb-images .thumb-item {
  width: calc(33.3333% - 20px);
}

.thumb-images .thumb-item img {
  width: 100%;
}

.product-details-content .section-title h2 {
  margin-bottom: 15px;
}

@media only screen and (min-width: 1400px) {
  .product-details-content .section-title h2 {
    font-size: 48px;
  }
}

.product-details-content .ratting-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-details-content .price {
  font-size: 24px;
  font-weight: 500;
  display: inline-block;
}

.product-details-content .add-to-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-details-content .add-to-cart input {
  max-width: 190px;
  font-weight: 500;
  padding: 8px 20px;
  text-align: center;
  margin: 20px 20px 0 0;
  font-family: "Roboto", sans-serif;
  border: 1px solid var(--border-color);
}

.product-details-content .add-to-cart .theme-btn {
  margin-top: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.product-details-content .category-tags li:not(:last-child) {
  margin-bottom: 8px;
}

.product-details-content .category-tags li b {
  min-width: 65px;
  font-weight: 500;
  display: inline-block;
  color: var(--heading-color);
}

.product-details-content .category-tags li span {
  margin-left: 15px;
  margin-right: 15px;
}

.product-details-content .category-tags li a:not(:last-child):after {
  content: ",";
  margin-right: 3px;
}

.product-details .tab-content h4 {
  margin-bottom: 15px;
}

@media only screen and (min-width: 1400px) {
  .product-details .tab-content h4 {
    font-size: 30px;
  }
}

.product-information-tab {
  padding: 25px 90px;
  background: var(--lighter-color);
}

@media only screen and (max-width: 767px) {
  .product-information-tab {
    padding: 15px 25px;
  }
}

.product-information-tab li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-information-tab li a {
  font-size: 24px;
}

@media only screen and (max-width: 575px) {
  .product-information-tab li a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .product-information-tab li a {
    font-size: 18px;
  }
}

.product-information-tab li a.active {
  font-weight: 500;
  color: var(--heading-color);
  text-decoration: underline;
}

.product-information-tab li:not(:last-child) {
  position: relative;
}

.product-information-tab li:not(:last-child):after {
  width: 7px;
  height: 7px;
  content: "";
  opacity: 0.25;
  border-radius: 50%;
  margin-left: 50px;
  margin-right: 50px;
  background: var(--heading-color);
}

@media only screen and (max-width: 575px) {
  .product-information-tab li:not(:last-child):after {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.review-form .ratting b {
  font-size: 20px;
  font-weight: 400;
  margin-right: 20px;
}

.review-form .form-group {
  margin-bottom: 30px;
}

.review-form .form-group .nice-select,
.review-form .form-group .form-control {
  line-height: 1.4;
  background: var(--lighter-color);
  border-color: var(--lighter-color);
}

@media only screen and (min-width: 480px) {

  .review-form .form-group .nice-select,
  .review-form .form-group .form-control {
    font-size: 20px;
  }
}

.review-form .form-group .nice-select::-webkit-input-placeholder,
.review-form .form-group .form-control::-webkit-input-placeholder {
  color: var(--heading-color);
}

.review-form .form-group .nice-select:-ms-input-placeholder,
.review-form .form-group .form-control:-ms-input-placeholder {
  color: var(--heading-color);
}

.review-form .form-group .nice-select::-ms-input-placeholder,
.review-form .form-group .form-control::-ms-input-placeholder {
  color: var(--heading-color);
}

.review-form .form-group .nice-select::placeholder,
.review-form .form-group .form-control::placeholder {
  color: var(--heading-color);
}

.review-form .form-group .nice-select:focus,
.review-form .form-group .form-control:focus {
  background: transparent;
  border-color: var(--heading-color);
}

.review-form .form-group .nice-select .current,
.review-form .form-group .form-control .current {
  font-weight: 400;
}

@media only screen and (min-width: 768px) {
  .review-form .theme-btn {
    padding: 14px 55px;
  }
}

/*******************************************************/
/******************** ## Team Area ********************/
/*******************************************************/
.team-member {
  margin-bottom: 50px;
}

.team-member .image {
  position: relative;
  margin-bottom: 30px;
}

.team-member .image img {
  width: 100%;
}

.team-member .image .social-links {
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 50%;
  bottom: 50px;
  padding: 4px 15px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background: var(--primary-color);
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.team-member .image .social-links a {
  color: white;
  padding: 5px;
  margin: 0 10px;
  font-size: 14px;
  display: inline-block;
}

.team-member .content h4 {
  margin-bottom: 2px;
}

.team-member .content .designation {
  font-size: 14px;
}

.team-member:hover .image .social-links {
  opacity: 1;
  bottom: 20px;
}

/*******************************************************/
/******************* ## Main Footer ********************/
/*******************************************************/
.main-footer.bgc-black-with-lighting:after,
.main-footer.bgc-black-with-lighting:before {
  width: 150px;
  height: 150px;
}

.main-footer.bgc-black-with-lighting:before {
  right: 45%;
}

/* Footer Widgets */
.footer-widget {
  margin-bottom: 50px;
}

.footer-title {
  color: white;
  margin-top: -5px;
  margin-bottom: 20px;
}

.widget_about p {
  color: rgba(255, 255, 255, 0.65);
}

@media only screen and (min-width: 1200px) {
  .widget_nav_menu {
    margin-left: auto;
    margin-right: auto;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }
}

.widget_nav_menu .list-style-one li a {
  color: rgba(255, 255, 255, 0.65);
}

.widget_nav_menu .list-style-one li a:hover {
  color: white;
}

.widget_newsletter form input {
  border: none;
  border-radius: 0;
  margin-top: 10px;
  padding: 14px 30px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 992px) {
  .widget_newsletter.style-two {
    text-align: center;
  }
}

.widget_newsletter.style-two form {
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
}

.widget_newsletter.style-two form input {
  margin: 0;
  padding: 10px 10px 10px 20px;
}

.widget_newsletter.style-two form .theme-btn {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.footer-instagram .instagram-item {
  width: 100%;
  margin-bottom: 30px;
}

/* Footer Bottom */
.footer-bottom {
  position: relative;
}

.footer-bottom .copyright-text {
  color: white;
}

.footer-bottom .copyright-text a {
  color: var(--primary-color);
  text-transform: uppercase;
}

.footer-bottom .scroll-top {
  position: absolute;
  top: -24px;
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
  background: var(--primary-color);
  line-height: 48px;
  border-radius: 50%;
  text-align: center;
}

.footer-bottom-nav {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: -15px;
  margin-right: -15px;
}

.footer-bottom-nav li {
  margin: 0 15px 5px;
}

.footer-bottom-nav li a {
  color: rgba(255, 255, 255, 0.65);
}

.footer-bottom-nav li a:hover {
  color: white;
}

.wave-shapes:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  /* width: 310px;
  height: 270px; */
  border-radius: 0 500px 0 0;
  -webkit-animation: leftRightOne 22s infinite;
  animation: leftRightOne 22s infinite;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(13.33%, rgba(171, 96, 52, 0)),
      to(rgba(171, 96, 52, 0.2)));
  background: -webkit-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: -o-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: linear-gradient(180deg,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
}

@media only screen and (max-width: 1399px) {
  .wave-shapes:before {
    /* width: 155px;
    height: 135px; */
  }
}

.wave-shapes:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  /* width: 380px;
  height: 335px; */
  border-radius: 0 400px 0 0;
  -webkit-animation: leftRightOne 22s infinite;
  animation: leftRightOne 22s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(13.33%, rgba(171, 96, 52, 0)),
      to(rgba(171, 96, 52, 0.2)));
  background: -webkit-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: -o-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: linear-gradient(180deg,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
}

@media only screen and (max-width: 1399px) {
  .wave-shapes:after {
    /* width: 190px;
    height: 170px; */
  }
}

.wave-shapes-two:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  /* width: 310px;
  height: 270px; */
  border-radius: 0 0 0 500px;
  -webkit-animation: leftRightTwo 22s infinite;
  animation: leftRightTwo 22s infinite;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(13.33%, rgba(171, 96, 52, 0)),
      to(rgba(171, 96, 52, 0.2)));
  background: -webkit-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: -o-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: linear-gradient(180deg,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
}

@media only screen and (max-width: 1399px) {
  .wave-shapes-two:before {
    /* width: 155px;
    height: 135px; */
  }
}

.wave-shapes-two:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  /* width: 380px;
  height: 335px; */
  border-radius: 0 0 0 400px;
  -webkit-animation: leftRightTwo 22s infinite;
  animation: leftRightTwo 22s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(13.33%, rgba(171, 96, 52, 0)),
      to(rgba(171, 96, 52, 0.2)));
  background: -webkit-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: -o-linear-gradient(top,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
  background: linear-gradient(180deg,
      rgba(171, 96, 52, 0) 13.33%,
      rgba(171, 96, 52, 0.2) 100%);
}

@media only screen and (max-width: 1399px) {
  .wave-shapes-two:after {
    /* width: 190px;
    height: 170px; */
  }
}

/* custom  */
/* Base styles for desktop view */

/* Chatbot */
.botIcon {
  bottom: 15px;
  position: fixed;
  z-index: 9999;
  right: 15px;
}

.iconInner {
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background: #9f7a39;
  background-position: 50%;
  background-size: 300%;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.7em;
  height: 2em;
  justify-content: center;
  width: 2em;
}

.botSubject,
.messages,
.showBotSubject .botIconContainer,
.showMessenger .botIconContainer {
  display: none;
}

/* .showBotSubject .botSubject {
      display: block;
  }
   .showMessenger .messages {
      display: block;
  }
   .botSubject {
      position: relative;
      width: 300px;
  }
   .botIcon .messages {
      background: none;
      border: none;
      border-radius: 0;
      bottom: 0;
      box-shadow: 0px 0px 30px -5px #000;
      min-height: 250px;
      max-height: 50vh;
      margin: 0;
      padding: 0;
      position: relative;
      right: 0;
      width: 300px;
  }
   .screen {
      right: 1%;
      background-color: #fff;
      position: absolute;
      height: 100%;
      overflow: hidden;
      width: 100%;
  }
   .botIcon .messages .body {
      box-sizing: border-box;
      height: calc(100% - 40px);
      overflow-y: auto;
      padding: 0px 12px 6px;
  }
   .botIcon .messages .body .msg {
      -webkit-animation: msg 1s;
      -moz-animation: msg 1s;
      -ms-animation: msg 1s;
      -o-animation: msg 1s;
      animation: msg 1s;
      background-color: #1666af;
      border-radius: 0px 10px 10px 10px;
      box-sizing: border-box;
      clear: both;
      color: #fff;
      margin-top: 10px;
      opacity: 1;
      display: block;
      overflow: hidden;
      padding: 10px 10px;
      width: 100%;
      float: left;
      width: auto;
  }
   .messages .body .msg.user {
      background-color: #fff;
      box-shadow: 0 0 4px rgba(0, 0, 0 , .5);
      border-radius: 10px 0px 10px 10px;
      color: #000;
      float: right;
      width: auto;
  }
   .botIcon .messages .footer, .botSubject > form {
      box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.5);
      bottom: 0;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      padding: 0;
      position: absolute;
      width: 100%;
      z-index: 100;
  }
   .botSubject > form {
      background-color: #fff;
      padding: 20px 15px;
      position: static;
  }
   .botIcon .messages .footer input, .botSubject input {
      border-width: 0px;
      box-sizing: border-box;
      font-size: 1em;
      margin: 0;
      padding-left: 10px;
  }
   .botIcon .messages .footer input[type="text"], .botSubject input[type="text"] {
      border: 1px solid;
      width: calc(100% - 70px);
  }
   .botIcon .messages .footer input[type="submit"], .messages .footer input[type="button"], .messages .footer button, .botSubject input[type="submit"], .botSubject input[type="button"], .botSubject button {
      width: 60px;
  }
   .botIcon .messages .footer input, .messages .footer button, .botSubject input, .botSubject button {
      height: 40px;
  }
   .botIcon .messages .footer button, .botSubject button {
      background-color: #123123;
      border: 0px solid black;
      color: white;
      width: 70px;
      margin: 0px;
      outline: none;
  }
   .botIcon .messages .footer button:hover, .botSubject button:hover {
      opacity: 0.7;
  }
   .botIcon .btn.btn-default.buyc_btn {
      border-radius: 0 !important;
      color: #fff;
      margin: 0;
      padding: 0;
  }
   .botIcon .btn.btn-default.buyc_btn:hover {
      padding: 0;
  }
   .closeBtn {
      background: #9f7a39;
      background: -webkit-linear-gradient(right, #00dbde, #fc00ff, #00dbde, #fc00ff);
      background: -o-linear-gradient(right, #00dbde, #fc00ff, #00dbde, #fc00ff);
      background: -moz-linear-gradient(right,#00dbde, #fc00ff, #00dbde,#fc00ff);
      background: linear-gradient(right, #00dbde, #fc00ff, #00dbde, #fc00ff);
      background-position: 50%;
      background-size: 300%;
      border-radius: 50%;
      height: 30px;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
      transform: translate(50%, -50%);
      width: 30px;
      z-index: 999;
  }
   .closeBtn::before, .closeBtn::after {
      background-color: #fff;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
      width: 20px;
  }
   .closeBtn::after {
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
      transform: translate(-50%, -50%) rotate(-45deg);
  }
   @-webkit-keyframes msg {
       0% {
          opacity: 0;
          -webkit-transform: translateY(20px);
      }
       100% {
          opacity: 1;
          -webkit-transform: translateY(0px);
      }
  }
   @-o-keyframes msg {
       0% {
          opacity: 0;
          -o-transform: translateY(20px);
      }
       100% {
          opacity: 1;
          -o-transform: translateY(0px);
      }
  }
   @-moz-keyframes msg {
       0% {
          opacity: 0;
          -moz-transform: translateY(20px);
      }
       100% {
          opacity: 1;
          -moz-transform: translateY(0px);
      }
  }
   @-ms-keyframes msg {
       0% {
          opacity: 0;
          -ms-transform: translateY(20px);
      }
       100% {
          opacity: 1;
          -ms-transform: translateY(0px);
      }
  }
   @keyframes msg {
       0% {
          opacity: 0;
          transform: translateY(20px);
      }
       100% {
          opacity: 1;
          transform: translateY(0px);
      }
  }
   @media only screen and (max-width: 412px) {
       .botSubject, .botIcon .messages {
          width: 280px;
      }
  }
   */

.botIcon .Messages,
.botIcon .Messages_list {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.chat_close_icon {
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 12px;
  z-index: 9;
}

.chat_on {
  background-color: #8a57cf;
  bottom: 20px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  color: #fff;
  cursor: pointer;
  display: block;
  height: 45px;
  padding: 9px;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 45px;
  z-index: 10;
}

.chat_on_icon {
  color: #fff;
  font-size: 25px;
  text-align: center;
}

.botIcon .Layout {
  -webkit-animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -ms-animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background-color: rgb(162 123 64);
  bottom: 20px;
  border-radius: 10px;
  box-shadow: 5px 0 20px 5px rgba(0, 0, 0, 0.1);
  box-sizing: content-box !important;
  color: rgb(255, 255, 255);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  max-height: 30px;
  max-width: 300px;
  min-width: 50px;
  opacity: 0;
  pointer-events: auto;
  position: fixed;
  -webkit-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  -ms-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  z-index: 999999999;
}

.botIcon .Layout-open {
  border-radius: 10px;
  color: #fff;
  height: 500px;
  max-height: 500px;
  max-width: 300px;
  overflow: hidden;
  -webkit-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -ms-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  width: 100%;
}

.botIcon .Layout-expand {
  right: 1%;
  display: none;
  height: 400px;
  max-height: 100vh;
  min-height: 300px;
}

.showBotSubject.botIcon .Layout-expand {
  right: 1%;
  display: block;
}

.botIcon .Layout-mobile {
  bottom: 10px;
}

.botIcon .Layout-mobile.Layout-open {
  min-width: calc(100% - 20px);
  width: calc(100% - 20px);
}

.botIcon .Layout-mobile.Layout-expand {
  border-radius: 0 !important;
  bottom: 0;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

.botIcon .Messenger_messenger {
  right: 2%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.botIcon .Messenger_header,
.botIcon .Messenger_messenger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.botIcon .Messenger_header {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #9f7a39;
  color: rgb(255, 255, 255);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 40px;
  padding-left: 10px;
  padding-right: 40px;
}

.botIcon .Messenger_header h4 {
  -webkit-animation: slidein 0.15s 0.3s;
  -ms-animation: slidein 0.15s 0.3s;
  animation: slidein 0.15s 0.3s;
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  font-size: 16px;
  opacity: 0;
}

.botIcon .Messenger_prompt {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.botIcon .Messenger_content {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 80px;
}

.botIcon .Messages {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.botIcon .Input {
  background-color: #fff;
  border-top: 1px solid #e6ebea;
  color: #96aab4;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-bottom: 15px;
  padding-top: 17px;
  position: relative;
  width: 100%;
}

.botIcon .Input-blank .Input_field {
  max-height: 20px;
}

.botIcon .Input_field {
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 20px;
  padding-right: 45px;
  resize: none;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px !important;
}

.botIcon .Input_button-emoji {
  right: 45px;
}

.botIcon .Input_button {
  background-color: transparent;
  border: none;
  bottom: 15px;
  cursor: pointer;
  height: 25px;
  outline: none;
  padding: 0;
  position: absolute;
  width: 25px;
}

.botIcon .Input_button-send {
  right: 15px;
}

.botIcon .Input-emoji .Input_button-emoji .Icon,
.botIcon .Input_button:hover .Icon {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.botIcon .Input-emoji .Input_button-emoji .Icon path,
.botIcon .Input_button:hover .Icon path {
  fill: #2c2c46;
}

.Icon svg {
  height: auto;
  width: 100%;
}

.msg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.msg.user {
  -webkit-box-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.msg+.msg {
  margin-top: 15px;
}

span.responsText {
  color: #000;
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  max-width: calc(100% - 50px);
}

.msg.user span.responsText {
  margin-left: 0;
  margin-right: 10px;
}

span.avtr {
  display: inline-block;
  width: 30px;
}

span.avtr figure {
  background-color: #ccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: block;
  margin: 0;
  padding-bottom: 100%;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-ms-keyframes appear {
  0% {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes slidein {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-ms-keyframes slidein {
  0% {
    opacity: 0;
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slidein {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media only screen and (max-width: 412px) {
  .botIcon .Layout-open {
    width: 250px;
  }
}

@media only screen and (max-width: 412px) {
  .botIcon .Layout-open {
    width: 250px;
  }
}

.homeslider {
  width: 55px;
  height: 30px;
  line-height: 30px;
  margin-right: 25px;
  text-align: center;
  border-radius: 15px;
  background: var(--primary-color);

}

.slider-item-new {
  width: 100%;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  opacity: 1;
}

.testimonials-three-slider .slick-initialized .slick-slide {
  padding: 0 10px;
}

/*** Slick Dots ***/
.slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slick-dots li {
  position: relative;
  cursor: pointer;
  margin: 8px !important;
  width: 6px !important;
  height: 6px !important;
  opacity: 0.3;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  background: #1D1B1A;
}

.slick-dots li button {
  opacity: 0;
  height: 0;
}

.slick-dots li:before {
  content: '';
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  position: absolute;
  border: 1px solid var(--primary-color);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slick-dots li.slick-active {
  background: var(--primary-color);
  opacity: 1;
}

.slick-dots li.slick-active:before {
  width: 21px !important;
  height: 21px !important;
}

.customeUnit{
  width: 100% !important;
}

.page-banner-area {
  z-index: 1;
  position: relative; }
  .page-banner-area::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.75;
    background-color: #1D1B1A; }
  .page-banner-area:before {
    mix-blend-mode: multiply; }