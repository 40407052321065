@import "~@flaticon/flaticon-uicons/css/all/all";
.comman-blue-font {
  color: #1c445f;
  font-family: Poppins;
}

@font-face {
  font-family: "Tiempos Text";
  src: url("/public/font/Tiempos Text Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Tiempos Text", sans-serif;
}

.comman-blue-font {
  color: #9f7a39;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.comman-blog-header {
  color: var(--blue, #1c445f);
  font-family: Poppins;
  color: var(--blue, #9f7a39);

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.comman-midium-grey {
  color: #c0c0c0;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.comman-header {
  color: var(--blue, #1c445f);
  font-family: Poppins;
  color: var(--blue, #9f7a39);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
}

.comman-midium-blue {
  color: var(--blue, #1c445f);
  font-family: Poppins;
  color: var(--blue, #9f7a39);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.comman-small-blue {
  color: var(--blue, #1c445f);
  font-family: Poppins;
  color: var(--blue, #9f7a39);

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.comman-grey {
  color: #555;
  font-family: Poppins;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blue-30 {
  color: var(--blue, #1c445f);
  font-family: Poppins;
  color: var(--blue, #9f7a39);

  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.comman-white {
  color: var(--white, #fff);
  font-family: Poppins;

  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  /* 125% */
}

.comman-white-sm {
  color: var(--white, #fff);
  font-family: Poppins;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 175% */
}

.top {
  margin-top: 30px;
}

.comman-test {
  color: #c1b1a6;
  font-family: Poppins;

  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.theme-color {
  color: #1c445f !important;
  color: #9f7a39 !important;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}

.home-banner {
  color: #1c445f;
  text-align: center;
  text-shadow: 0px 0px 13px rgba(255, 255, 255, 0.25);
  font-family: Poppins;
  color: #9f7a39;
  text-align: center;
  text-shadow: 0px 0px 13px rgba(255, 255, 255, 0.25);

  font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.susscess-font {
  font-weight: 500;
  color: #16c85e;
  background: rgba(22, 200, 94, 0.06);
  padding: 5px;
  width: auto;
  text-align: center;
  border-radius: 8px;
}

.check-in {
  border: 1px solid #f0f0f0;
  /* border: 1px solid #f0f0f0; */
  padding: 15px;
}

.checkIn-box {
  border-radius: 8px;
  background: #fff;
  height: 60px;
  padding: 20px;
}

.checkBox {
  /* width: 730px; */
  background: #e5ecef;
  border-radius: 16px;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.1);
}

.slick-slide.slick-active-text.slick-center.slick-current {
  width: 287px !important;
}

.comman-padding {
  padding: 15px;
}

.comman-bg {
  background: #1c445f !important;
  background: #9f7a39 !important;
}

.profile-edit {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch-btn {
  /* background: rgba(28, 68, 95, 0.08);
  border-radius: 70px; */
  width: 32%;
}

.switch-bg {
  background: rgba(28, 68, 95, 0.08);
}

/* .aminites-main {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.67) 0%,
      rgba(0, 0, 0, 0.67) 100%
    ),
    url(/public/images/back.png) lightgray 50% / cover no-repeat, #f4f9fb;
} */

.aminites-main {
  background-image: url(/public/images/back.png);
  padding: 40px;
}

.banner-bg {
  background-image: url(/public/images/drone3.jpeg);
}

.icon-box {
  width: 100%;
  height: 200px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
}

/* ------------mobile------------ */
@media (max-width: 767px) {
  .icon-box {
    width: 150px;
    height: 150px;
  }

  .aminites-main {
    padding: 10px;
  }

  .mobile-top {
    margin-top: 2.5rem;
  }

  iframe.ifream {
    width: 150px;
    height: 150px;
  }

  .checkBox {
    overflow-x: scroll;
  }

  .home-banner {
    font-size: 45px;
  }

  .comman-header {
    font-size: 20px;
    line-height: 24px;
  }

  .unit-padding {
    margin-top: 10px;
    padding: 0px !important;
  }
}

.unit-text {
  position: absolute;
  bottom: -29px;
  background: white;
  width: 80% !important;
  margin: 0 auto;
  border-radius: 20px;
  left: 10%;
}

.footer-icon {
  height: 48px;
  width: 48px;
  border: 1px solid white;
  border-radius: 50%;
}

.testimonuls-bg {
  background-image: url("/public/images/testimonuls.png");
}

.test-box {
  height: 40px;
  width: 40px;
}

.bg-test {
  background: #d1dee4;
}

input.react-datepicker-ignore-onclickoutside {
  outline: none;
}

/* .react-datepicker-wrapper {
  width: 6rem;
  width: 9rem;
} */

.react-datepicker-wrapper input[type="text"] {
  width: 100%;
  outline: none;
}

.img-redius {
  border-radius: 300px;
}

.amenities-box {
  border-radius: 24px;
  border: 1px solid #1c445f;
  border: 1px solid #9f7a39;
  background: #fff;
  padding: 30px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  height: 400px;
}

.property-box {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.71);
  padding: 5px;
}

.filter-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 10px;
  width: 271px;
  z-index: 999;
}

.increment {
  border-radius: 4px;
  background: #d1d8dc;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor:hover {
  cursor: pointer;
}

.property-box {
  border-radius: 16px;
  border: 1px solid var(--font, #555);
  padding: 20px;
  width: 20rem;
}

.payment-info {
  border-radius: 15px;
  background: var(--white, #fff);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
}

.payment-checkIn {
  border-radius: 16px;
  background: #f5fbfe;
  padding: 20px;
}

.input-box {
  border-radius: 8px;
  background: #f8f8f9;
  outline: none;
  padding: 20px;
  width: 100%;
}

textarea.text-area {
  border-radius: 8px;
  background: #f8f8f9;
  outline: none;
  padding: 20px;
  width: 100%;
}

.box-payment {
  width: 80px;
  height: 80px;
}

.contact-box {
  border-radius: 50%;
  border: 1px solid #9f7a39;
}

.contact-line {
  border-left: 2px solid #9f7a39;
}

.contact-line {
  border-left: 2px solid #9f7a39;
  width: 279px;
  height: 90px;
}

.active-bg {
  background: #f8f8f9;
}

.active-text-bg {
  background: #f8f8f9;
}

.profile-active-text {
  padding: 20px;
  border-radius: 5px;
}

.profile-active:hover {
  .profile-active-text:hover {
    background-color: #f8f8f9;
    cursor: pointer;
  }
}

.unit-padding {
  padding: 50px;
}

.unit-box {
  width: fit-content;
  border: 1px solid #1c445f;
  border: 1px solid #9f7a39;
  padding: 12px;
  border-radius: 5px;
  margin-top: 10px;
}

/* .select {
  outline: none;
  width: 100%;
  height: 30px;
  margin-left: 10px;
  border-radius: 5px;
} */

.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  padding: 8px 30px 8px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  outline: none;
  margin-left: 10px;
}

.select:hover {
  cursor: pointer;
}

.select option {
  transition: all 0.3s ease;
}

.select option:hover {
  background-color: #f0f0f0;
  cursor: pointer;
  /* Added cursor pointer for options */
}

.select:after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Smooth transition for select options */
.select option {
  transition: all 0.3s ease;
}

.select option:hover {
  background-color: #f0f0f0;
}

.header-profile {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.edit-image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.booking-container {
  max-height: 600px;
  overflow-y: scroll;
}

.react-datepicker__input-container input {
  background: transparent;
}

.testimonuls-img {
  width: 40px !important;
  height: 40px;
}

.people-list {
  width: 260px;
  float: left;
}

.people-list .search {
  padding: 20px;
}

.people-list input {
  border-radius: 3px;
  border: none;
  padding: 14px;
  color: white;
  background: #6a6c75;
  width: 90%;
  font-size: 14px;
}

.people-list .fa-search {
  position: relative;
  left: -25px;
}

.people-list ul {
  padding: 20px;
  height: 770px;
}

.people-list ul li {
  padding-bottom: 20px;
}

.people-list img {
  float: left;
}

.people-list .about {
  float: left;
  margin-top: 8px;
}

.people-list .about {
  padding-left: 8px;
}

.people-list .status {
  color: #92959e;
}

.chat {
  background: #f2f5f8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #434651;
}

.chat .chat-header {
  padding: 20px;
  border-bottom: 2px solid white;
}

.chat .chat-header img {
  float: left;
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}

.chat .chat-header .chat-with {
  font-weight: bold;
  font-size: 16px;
}

.chat .chat-header .chat-num-messages {
  color: #92959e;
}

.chat .chat-header .fa-star {
  float: right;
  color: #d8dadf;
  font-size: 20px;
  margin-top: 12px;
}

.chat .chat-history {
  padding: 30px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: calc(100vh - 190px);
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
}

.chat .chat-history .message {
  border-radius: 10px !important;
  padding: 8px 12px !important;
  font-size: 18px !important;
  line-height: 20px !important;
  word-break: normal;
  word-wrap: break-word;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 20px;
  width: fit-content;
  background: transparent !important;
  /* position: relative; */
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #86bb71;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .my-message {
  background: #86bb71;
}

.chat .chat-history .other-message {
  background: #94c2ed;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #94c2ed;
  left: 93%;
}

.chat .chat-message {
  padding: 30px;
}

.chat .chat-message textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  font: 14px/22px "Lato", Arial, sans-serif;
  margin-bottom: 10px;
  border-radius: 5px;
  resize: none;
}

.chat .chat-message .fa-file-o,
.chat .chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}

.chat .chat-message button {
  float: right;
  color: #94c2ed;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #f2f5f8;
}

.chat .chat-message button:hover {
  color: #75b1e8;
}

.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86bb71;
}

.offline {
  color: #e38968;
}

.me {
  color: #94c2ed;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.chat-box {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  border: 1px solid white;
}

.show-hide {
  height: 20px;
  position: absolute;
  right: 20px;
  top: 30px;
}

p.carousel-status {
  visibility: hidden;
}

.blog-img {
  width: 500px;
  height: 120px;
}

.large-font {
  font-size: 2rem;
}

.semi-large-font {
  font-size: 1rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-button {
  position: absolute;
  top: 0;
  right: 0;
}

.image-preview {
  height: 100px;
  width: 100px;
}

.preview-container {
  position: relative;
  width: 100px;
}

.aminites-card {
  background: linear-gradient(119.17deg,
      #b0b0b0 1.14%,
      rgba(175, 175, 175, 0.1) 100%);
  width: 100%;
  border-radius: 10px;
  color: #fff;
  font-size: 35px;
  font-weight: 600;
}

.chat-modal {
  width: 500px;
}

.react-responsive-modal-container {
  text-align: right !important;
}

.chat-modal {
  max-width: 452px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  max-height: calc(100% - 58px);
  min-height: 142px;
  margin: 34px 24px 0;
  border-radius: 6px;
  box-shadow: 42 7px 6px 1px rgba(0, 0, 0, 0.16);
  transform: translateY(0);
}

.my-message {
  background: rgb(245, 248, 250) !important;
  color: rgb(65, 65, 65) !important;
}

.chat .chat-history {
  background-color: #fff;
  /* margin-top: 60px; */
}

.chat .chat-header {
  background-color: #fff;
  box-shadow: inset 0px 0px 16px 5px #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 7px;
  right: 5px;
  background-color: #1c442e !important;
  display: flex;
  border-radius: 8px;
}

.react-responsive-modal-closeButton svg {
  fill: #fff !important;
}

.react-responsive-modal-overlay {
  display: none;
}

.message-question .other-message {
  color: #fff;
  background-color: #1c445f;
  background-color: #9f7a39;
}

.message-option {
  text-align: right;
}

ul {
  width: fit-content;
  float: right;
}

li.cursor.message-option .my-message {
  text-align: left;
  border: 1px solid #1c445f;
  color: #1c445f;
}

li.cursor.message-option .my-message:hover {
  background-color: #1c445f !important;
  border: 1px solid #9f7a39;
  color: #9f7a39;
}

li.cursor.message-option .my-message:hover {
  background-color: #9f7a39 !important;
  color: #fff !important;
}

.message.my-message.message-answer {
  /* background-color: #1c445f !important; */
  background-color: #d9c298 !important;
  color: #fff !important;
  margin-bottom: 0px !important;
}

.header-bar {
  background-color: #1c445f !important;
  background-color: #9f7a39 !important;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 2px;
  z-index: 123;
}

.react-responsive-modal-closeButton {
  z-index: 124;
}

.header-bar img {
  width: 50px;
  height: 50px;
  border: 1px solid #6a6c75;
  margin: 5px;
  padding: 2px;
  background-color: #6a6c75;
  border-radius: 50%;
}
.react-responsive-modal-modal {
  max-width: 1000px !important;
  display: block !important;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
  margin-left: auto !important;
  margin-right: auto !important;
}

.react-responsive-modal-modal {
  position: relative !important;
  padding: 0 !important;
  
}

.chat .chat-history .message.other-message {
  background-color: #1c445f !important;
  background-color: #9f7a39 !important;
  color: #fff !important;
  margin-bottom: 0 !important;
}

.chat-history ul {
  display: block;
  width: 100%;
}

.chat-bubble {
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 5px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #1c445f;
  background-color: #9f7a39;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #1c445f;
    background-color: #9f7a39;
  }

  28% {
    transform: translateY(-7px);
    background-color: #9ecab9;
  }

  44% {
    transform: translateY(0px);
    background-color: #b5d9cb;
  }
}

.banner-checkaction h3 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
}

.banner-checkaction p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.banner-checkaction select,
input {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.banner-checkaction .check-in.w-full.flex.items-center {
  justify-content: center;
  padding: 0;
  margin: 10px 0;
}

#scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#scroll-bar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0.24, #1c445f),
      color-stop(0.32, #1c445f),
      color-stop(0.16, #1c445f) color-stop(0.24, #9f7a39),
      color-stop(0.32, #9f7a39),
      color-stop(0.16, #9f7a39));
}

/* ::-webkit-scrollbar {
  display: none;
} */
.carousel {
  margin-top: 20px;
}

.carousel .slide img {
  object-fit: cover;
}

/* .amenities-box img {
  min-width: 200px;
  min-height: 200px;
  object-fit: cover;
} */
.property .image img {
  height: 243px;
  width: 100%;
}

/* object-fit: cover; */
.property .image-new img {
  height: 243px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.property .w-full.flex.mt-2 .w-1\/2.mx-2.relative {
  margin-right: 0;
}

@media (max-width: 1280px) {
  .banner-checkaction .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .banner-checkaction .check-in {
    justify-content: space-evenly;
  }

  .banner-checkaction .check-in.w-full.flex.items-center {
    justify-content: center;
    margin: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .gallery-block .w-full.rounded-lg {
    min-height: auto !important;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 640px) {
  .banner-checkaction .check-in {
    flex-direction: column;
  }

  .banner-checkaction .checkIn-box {
    width: 100% !important;
    margin-bottom: 17px;
  }

  .flex.items-center.comman-grey.checkIn-box.mx-2.w-full {
    margin-bottom: 0;
    margin-left: 0;
  }

  .banner-checkaction .flex.items-center.comman-grey.checkIn-box {
    justify-content: space-around;
  }
}

iframe.ifream {
  max-width: 100%;
}

.checkIn-box {
  padding: 20px 32px;
}

@media screen and (max-width: 414px) {
  .banner-checkaction .mt-16.grid.grid-cols-1.sm\:grid-cols-1.md\:grid-cols-1.lg\:grid-cols-2.gap-4.p-4 {
    padding: 0;
  }

  .banner-checkaction .check-in.w-full.flex.items-center {
    padding: 0;
  }

  .banner-checkaction .flex.items-center.comman-grey.checkIn-box {
    padding: 10px;
  }
}

.scroll-container {
  width: 100%;
  overflow: hidden;
}

.scroll-text {
  white-space: nowrap;
  animation: scroll-left 5s linear infinite;
  /* Adjust timing as needed */
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.border-tour {
  border: 2px solid green;
}

button.text-white.font-medium.rounded-lg.text-sm.px-2.py-2\.5.me-2.mb-2.focus\:outline-none.comman-bg.w-full.mt-2:hover {
  background-color: transparent;
  border: 2px solid #1c445f;
  color: #1c445f;
}

.image img:hover {
  transform: scale(1.2);
}

.image img {
  overflow: hidden !important;
  height: 243px;
  width: 100%;
  /* object-fit: cover; */
  object-position: center;
  transition: 0.5s all ease-in-out;
  z-index: 999;
}

.image {
  overflow: hidden;
}

button.text-white.font-medium.rounded-lg.text-sm.px-2.py-2\.5.me-2.mb-2.focus\:outline-none.comman-bg.w-full.mt-2:hover {
  background-color: transparent;
  border: 2px solid #9f7a39;
  /* color: #9f7a39; */
  color: white;
}

.virtual-tour-container {
  position: relative;
}

.custom-hotspot {
  width: 24px;
  height: 24px;
  background-color: #ff0000;
  border-radius: 50%;
  animation: pulse 2s infinite;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
  }
}

.grediant {
  background: linear-gradient(180deg,
      rgb(175 201 211 / 46%) 0%,
      rgba(255, 255, 255, 1) 25%,
      rgba(255, 255, 255, 1) 80%,
      #99c4ede0 100%);
  /* background: linear-gradient(
    180deg,
    rgb(175 201 211 / 46%) 20%,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 1) 70%,
    #99c4ede0 100%
  ); */
  background: linear-gradient(180deg,
      rgb(225 217 191 / 84%) 20%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 1) 70%,
      #ebdec6 100%);
}

.grediand-header {
  background: linear-gradient(rgb(220 231 235 / 94%) 100%,
      rgba(255, 255, 255, 1) 25%,
      rgba(255, 255, 255, 1) 80%);
  /* background: linear-gradient(
    rgb(220 231 235 / 94%) 100%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 80%
  ); */
  background: #ebdfc7;
}

.active-text {
  background-color: #9f7a39;
}

.image {
  position: relative;
  overflow: hidden;
}

.image img {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* max-height: 200px; */
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.enlarged-image {
  border-radius: 10px;
  max-width: 90%;
  max-height: 50%;
}

.react-responsive-modal-modal {
  max-width: 550px;
}

.background-img {
  background-image: url("../public/images/bathroom.jpg");
  /* overflow-y: scroll; */
  /* position: fixed; */
  /* height: 100%; */
  /* width: 100%; */
}

::-webkit-scrollbar {
  display: none;
}

.breathe {
  animation: breathe 4s ease-in-out infinite;
}

@keyframes breathe {

  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
}


.edit-profile-side {
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.customeWidth{
  width: 100% !important;
}

.chat_header_new{
  /* background-color: #9f7a39; */
  align-items: center;
    background-color: #9f7a39;
    color: rgb(255, 255, 255);
    flex-shrink: 0;
    height: 40px;
    padding-left: 10px;
    padding-right: 40px;
}

.custom-modal-class{
  position: absolute !important;
  right: 15px !important;
  width: 333px !important;
  height: 420px !important;
  .react-responsive-modal-closeButton{
    display: none;
  }
}
.react-responsive-modal-container{
  overflow-y: hidden !important;
}

.modal-enter {
  transform: translateY(0);
  opacity: 1;
}

.modal-leave {
  transform: translateY(50px);
  opacity: 0;
}