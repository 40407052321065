@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* new Style */

@layer base {
  html {

    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth;
    }
  }

 
body {
  color: var(--base-color);
  background: white;
  font-weight: 400;
  line-height: 32px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  --base-color: #696969;
  --heading-color: #0A0807;
  --primary-color: #9f7a39;
  --lighter-color: #F4F2F1;
  --black-color: #0C0B0B;
  --border-color: #D9DFE7; }
}
